import { useEffect, useState } from "react";
import useQuery, { IQueryFiltered } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useFilteredItems = ({ parentId, isObjective }: { parentId: string, isObjective: boolean }) => {
	const { query } = useQuery<IQueryFiltered>();

	const { wholesalerId } = query;
	const [pageSize, setPageSize] = useState(10);

	const loading = commonObjIncStoreZustand((state) => state.loadingFiltered);

	const currentPage = commonObjIncStoreZustand(
		(state) => state.currentFilteredPage
	);
	const setCurrentPage = commonObjIncStoreZustand(
		(state) => state.setCurrentFilteredPage
	);

	const totalPages = commonObjIncStoreZustand(
		(state) => state.totalFilteredPages
	);

	const filteredItems = commonObjIncStoreZustand(
		(state) => state.filteredItems
	);

	useEffect(() => {
		commonObjIncStoreZustand.getState().fetchFilteredItems({
			wholesalerId,
			page: currentPage,
			limit: pageSize,
			parentId,
			...(isObjective ? { isObjective: true } : { isIncentive: true }),
		});
	}, [wholesalerId, currentPage, pageSize, parentId, isObjective]);

	return {
		filteredItems,
		currentPage,
		loading,
		totalPages,
		setCurrentPage,
		setPageSize,
	};
};

export default useFilteredItems;
