import { FormProvider, useForm } from "react-hook-form";
import { CustomObjIncAccountFilters, CustomObjIncProductFilters, initialSubInc, initialSubObj, Item, MeasureState, MeasureSymbol, SemiItem } from "../../types/commonObjIncTypes";
import { useStyles } from "./EditComponent.styles";
import { useEffect, useState } from "react";
import useFilteredItems from "../../hooks/useFilteredItems";
import useGroupedItems from "../../hooks/useGroupedItems";
import { defaultAdvancedPayments, getPayoutType, GroupedTableItem, initializeMeasures, PayoutDetails, updatedUsers, UserRole } from "./utils";
import useHandleSubItemUpdate from "../../hooks/useHandleSubItemsUpdate";
import useItemValidation from "../../hooks/useItemValidation";
import { Objective, ObjectiveTypeEnum, ObjectiveUser } from "../../types/objectiveTypes";
import { Incentive, IncentiveUser } from "../../types/incentivesTypes";
import { DialogContent, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, Button, FormLabel, TextField } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { DividerFormControl } from "../../common/DividerFormControl";
import { capitalizeFirstLetter } from "../../utils";
import { AddNewSubItemComponent } from "../addObjectiveComponents/addNewSubItem";
import { SubItemList } from "../itemsList/SubItemList";
import { QuantityForm } from "../quantityObjective/QuantityForm";
import { CustomAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/CustomAccountsSelection";
import { ManualAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/ManualAccountsSelection";
import { CustomProductsSelection } from "./accountsProductsUsersSelect/productsSelect/CustomProductsSelection";
import ManualProductsSelection from "./accountsProductsUsersSelect/productsSelect/ManualProductsSelection";
import SelectionTabs from "./accountsProductsUsersSelect/SelectionTabs";
import { CustomUsersSelection } from "./accountsProductsUsersSelect/usersSelect/CustomUsersSelection";
import { EditSubItemComponent } from "./EditSubItemComponent";
import { GroupedTable } from "./groupedObjective/GroupedTable";
import MinimumQuantityComponent from "./minimumQuantityComponent/MinimumQuantityComponent";
import { NameDescriptionPeriodComponent } from "./nameDescriptionPeriod";
import { PayoutComponent } from "./payoutComponents";
import { MultiPayoutComponent } from "./payoutComponents/MultiPayoutComponent";
import { TagComponent } from "./tagComponent/TagComponent";
import { TargetComponent } from "./targetComponent";
import { ValueInput } from "./valueObjective/ValueInputComponent";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import useSubItem from "../../hooks/useSubItem";


interface EditItemComponentProps {
	initialItemState: Item;
	isObjective: boolean;
	subItems: (Objective | Incentive)[];
	onSave: (item: Item) => void;
	onClose: () => void;
}

const EditItemComponent: React.FC<EditItemComponentProps> = ({
	initialItemState,
	isObjective,
	subItems,
	onSave,
	onClose,
}) => {
	const classes = useStyles();
	const methods = useForm();

	const [item, setItem] = useState<Objective | Incentive>(initialItemState);
	const [open, setOpen] = useState(false);
	const [tag, setTag] = useState<string>(item.tag || "");
	const [localSubItems, setLocalSubItems] = useState(subItems);
	const [selectedGroupedItems, setSelectedGroupedItems] =
		useState<GroupedTableItem[]>();
	const [selectionType, setSelectionType] = useState<boolean>(
		item.customAccountsSelected ?? true
	);
	const [selectionTypeProd, setSelectionTypeProd] = useState<boolean>(
		item.customProductsSelected ?? true
	);
	const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>({
		target: item?.target || 0,
		payoutType: item?.payoutType || "perAction",
		allOrNothingAmount: item?.quantityPayout || 0,
		perActionAmount: item?.quantityPayout || 0,
		atRiskAmount: item?.quantityPayout || 0,
		threshold: item?.threshold || { units: "percentage", value: 0 },
		cap: item?.cap || { units: "percentage", value: 100 },
		isOpenEnded: item.isOpenEnded,
		advancedBudget: item.advancedBudget || 0,
		advancedPayments: item.advancedPayments || defaultAdvancedPayments,
		isAccountSpecific: item.isAccountSpecific || false,
		isFullAmount: item.isFullAmount || false,
	});
	const [selectedProductsFilters, setSelectedProductsFilters] =
		useState<CustomObjIncProductFilters>({
			...{
				supplier: [],
				brand: [],
				packageType: [],
				size: [],
				productType: [],
				brandFams: [],
			},
			...Object.fromEntries(
				Object.entries(initialItemState.productsFilters || {}).filter(
					([key]) => key !== "premise"
				)
			),
			brandFams: initialItemState.productsFilters?.brandFams || [],
		});
	const [selectedAccountsFilters, setSelectedAccountsFilters] =
		useState<CustomObjIncAccountFilters>(
			initialItemState.accountsFilters || {
				accountType: [],
				premise: [],
				chain: [],
				location: [],
			}
		);
	const [measures, setMeasures] = useState(
		initializeMeasures(
			initialItemState.measure,
			initialItemState.isAccountSpecific || false
		)
	);
	const [valueInputType, setValueInputType] = useState(
		item.valueInputType || "gt"
	);
	const [valueInput, setValueInput] = useState(item.valueInput);
	const [selectedUsers, setSelectedUsers] = useState<UserRole[]>(
		item?.users
			? item?.users?.map((user) => ({
				user: user.user,
				isTeamLead: user.isTeamLead || false,
				isLeadership: user.isLeadership || false,
			}))
			: []
	);
	const [selectedSubItemId, setSelectedSubItemId] = useState<string | undefined>(undefined);
	const [isEditSubItemModalOpen, setIsEditSubItemModalOpen] =
		useState(false);
	const [hideFromSalesReps, setHideFromSalesReps] = useState<boolean>(
		item.hideFromSalesReps || false
	);
	const [minQuantityValue, setMinQuantityValue] = useState(
		item.minQuantityValue || 0
	);


	const { isFormValid } = useItemValidation(item, localSubItems);

	const {
		filteredItems,
		currentPage,
		loading,
		totalPages,
		setCurrentPage,
	} = useFilteredItems({ parentId: item._id, isObjective: isObjective });

	const {
		addGroupedItems,
		groupedChildren,
		totalGroupedPages,
		loadingGrouped,
		setCurrentGroupedPage,
		currentGroupedPage,
	} = useGroupedItems({ itemId: item._id, isObjective: isObjective });

	const { addSubItem, editSubItem, deleteSubItem } = useHandleSubItemUpdate({
		subItemType: isObjective ? "subObjective" : "subIncentive",
	});

	const { subItem, subItemLoading } = useSubItem({ subItemId: selectedSubItemId, isObjective: isObjective, parentItemId: item._id })

	useEffect(() => {
		setMeasures(
			initializeMeasures(initialItemState.measure, item.isAccountSpecific)
		);
	}, [item.isAccountSpecific, initialItemState.measure]);

	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleTabChange = (newValue: boolean) => {
		setSelectionType(newValue);
	};
	const handleProdTabChange = (newValue: boolean) => {
		setSelectionTypeProd(newValue);
	};

	const handleChangeGroupedItems = (
		updatedGroupedItems: (Objective | Incentive)[]
	) => {
		const formattedGroupedItems = updatedGroupedItems.map((obj) => ({
			id: obj._id,
			weight: obj.weight != null ? obj.weight : 0,
		}));
		setSelectedGroupedItems(formattedGroupedItems);
	};

	const handleMinQuantityValueChange = (value: number) => {
		setMinQuantityValue(value);
		setItem((prev) => ({
			...prev,
			minQuantityValue: value,
		}));
	};

	const handleValueInputChange = (name: string, value: string | number) => {
		if (name === "valueInputType") {
			setValueInputType(value as string);
			setItem((prev) => ({
				...prev,
				valueInputType: value as string,
			}));
		} else if (name === "valueInput") {
			setValueInput(value as number);
			setItem((prev) => ({
				...prev,
				valueInput: value as number,
			}));
		}
	};

	const handleHideFromSalesRepsChange = (checked: boolean) => {
		setItem((prevItem) => ({
			...prevItem,
			hideFromSalesReps: checked,
		}));
		setHideFromSalesReps(checked);
	};

	const handleMeasureChange = (
		label?: string,
		startDate?: string,
		endDate?: string,
		checked?: boolean,
		symbol?: MeasureSymbol,
		trackingOption?: string,
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		referenceTrackingPeriod?: string
	) => {
		setMeasures((prevMeasures) =>
			prevMeasures.map((measure) =>
				measure.label === label
					? {
						...measure,
						startDate,
						endDate,
						checked,
						symbol,
						trackingOption,
						trackingPeriodStart,
						trackingPeriodEnd,
						referenceTrackingPeriod,
					}
					: { ...measure, checked: false }
			)
		);
	};

	const handleUserSelectionChange = (selectedUsers: UserRole[]) => {
		setSelectedUsers(selectedUsers);

		const advancedBudget =
			item.type === "grouped" ? payoutDetails.advancedBudget : 0;
		const advancedPayments =
			item.type === "grouped" ? payoutDetails.advancedPayments : [];

		setItem((prev) => ({
			...prev,
			users: updatedUsers(
				selectedUsers,
				item,
				payoutDetails.target,
				getPayoutType(payoutDetails) || 0,
				payoutDetails.payoutType,
				item.isOpenEnded,
				payoutDetails.cap,
				payoutDetails.threshold,
				minQuantityValue,
				advancedBudget,
				advancedPayments
			) as (ObjectiveUser | IncentiveUser)[],
		}));
	};

	const handleChange = (name: string, value: string | number) => {
		setItem((prev) => {
			const updatedItem = { ...prev, [name]: value };
			return updatedItem;
		});
	};

	const getMeasuresForSave = (measures: MeasureState[]) => {
		return measures.filter((measure: MeasureState) => measure.checked)[0];
	};

	const handleEditSubItem = (subItemId: string) => {
		if (isEditSubItemModalOpen) {
			setIsEditSubItemModalOpen(false);
			setSelectedSubItemId(undefined);
		}
		if (subItemId){
			setSelectedSubItemId(subItemId);
			setIsEditSubItemModalOpen(true);
		}
	};

	const handleAddSubItem = async (
		newSubItemData: Omit<Objective | Incentive, "_id">,
		parentItemId: string,
	) => {
		newSubItemData.customPeriodStart = item.customPeriodStart;
		newSubItemData.customPeriodEnd = item.customPeriodEnd;

		const subIt = await addSubItem(
			{ ...newSubItemData, parentItemId } as Omit<Objective | Incentive, "_id">,
			parentItemId,
		);
		setLocalSubItems((prevSubItems) => [...prevSubItems, subIt]);

		setSelectedSubItemId(subIt._id);
		setIsEditSubItemModalOpen(true);
	};

	const handleDuplicateSubItem = (
		id: string,
		parentItemId: string
	) => {
		const subItem = localSubItems?.find((it) => it._id === id);
		if (subItem) {
			const newName = `${subItem.name} copy`;
			const {
				_id: objId,
				customId,
				users: originalUsers,
				...restOfSubIt
			} = subItem;

			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
				totalAchieved: 0,
				percentageAchieved: 0,
				totalItems: 0,
				totalPayout: 0,
				checks: [],
				isEdited: false,
				minQuantityValue: 0,
				advancedBudget: 0,
				advancedPayments: [],
				isAccountSpecific: false,
				isFullAmount: false,
			}));

			handleAddSubItem(
				{
					...restOfSubIt,
					name: newName,
					favorite: false,
					users: initializedUsers,
				},
				parentItemId,
			);
		}
	};

	const handleDeleteSubItem = async (id: string) => {
		const subType = isObjective ? "subObjective" : "subIncentive";
		await deleteSubItem(id, subType);
		setLocalSubItems((prevSubItems) =>
			prevSubItems.filter((sub) => sub._id !== id)
		);
	};

	const handleReorderSubItems = async (
		newOrder: (Objective | Incentive)[]
	) => {
		const updatedSubItems = newOrder.map((item, index) => ({
			...item,
			order: index + 1,
		}));

		setLocalSubItems(updatedSubItems);

		await Promise.all(
			updatedSubItems.map((subItem) =>
				editSubItem(subItem._id, subItem, true)
			)
		);
	};

	const handleSaveSubItem = (editedSubItem: Objective | Incentive) => {
		const subItemId = editedSubItem._id;

		const updatedSubItems = localSubItems?.map((subIt) =>
			subIt._id === editedSubItem._id ? editedSubItem : subIt
		);

		setLocalSubItems(updatedSubItems)

		editSubItem(subItemId, editedSubItem, false);

		setIsEditSubItemModalOpen(false);
	};

	const handleTargetChange = (value: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			target: value,
		}));
	};

	const handleIsOpenEndedChange = (checked: boolean) => {
		setItem((prevItem) => ({
			...prevItem,
			isOpenEnded: checked,
			isAccountSpecific: false,
		}));
		setPayoutDetails((prevItem) => ({
			...prevItem,
			isOpenEnded: checked,
			isAccountSpecific: false,
			payoutType: "perAction",
		}));
	};

	const handleIsAccountSpecificChange = (checked: boolean) => {
		setItem((prevItem) => ({
			...prevItem,
			isAccountSpecific: checked,
			isOpenEnded: false,
		}));

		setPayoutDetails((prevItem) => ({
			...prevItem,
			isAccountSpecific: checked,
			isOpenEnded: false,
		}));
	};

	const handleTagChange = (newTag: string) => {
		setTag(newTag);
		setItem((prevItem) => ({
			...prevItem,
			tag: newTag
		}));
	}

	const handleMultiPayoutChange = (quantityPayout: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			quantityPayout: quantityPayout,
		}));

		setItem((prev) => ({
			...prev,
			quantityPayout: quantityPayout,
		}));
	};

	const handleItemSubmit = () => {
		if (!isFormValid()) {
			return;
		}

		const transformedUsers = selectedUsers.map((selectedUserId) => {
			// Find the user in the existing users array
			const existingUser = item?.users?.find(
				({ user }) => user === selectedUserId.user
			);

			if (existingUser) {
				return existingUser;
			}

			// If the user is new, return a new user object with default values
			return {
				user: selectedUserId.user,
				isTeamLead: selectedUserId.isTeamLead || false,
				isLeadership: selectedUserId.isLeadership || false,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				goal: null,
				target: payoutDetails.target,
				payout: getPayoutType(payoutDetails) || 0,
				payoutType: payoutDetails.payoutType,
				isOpenEnded: item.isOpenEnded,
				cap: payoutDetails.cap,
				threshold: payoutDetails.threshold,
				isEdited: false,
				minQuantityValue: minQuantityValue,
				isAccountSpecific: payoutDetails.isAccountSpecific,
				isFullAmount: payoutDetails.isFullAmount,
			};
		});

		let updatedItem: Objective | Incentive = {
			...item,
			users: transformedUsers,
			payoutType: payoutDetails.payoutType,
			threshold: payoutDetails.threshold,
			cap: payoutDetails.cap,
			target: payoutDetails.target,
			payout: payoutDetails.target,
			isAccountSpecific: payoutDetails.isAccountSpecific,
			isFullAmount: payoutDetails.isFullAmount,
			valueInputType: valueInputType,
			valueInput: valueInput,
			isValid: true,
			productsFilters: selectedProductsFilters,
			accountsFilters: selectedAccountsFilters,
			hideFromSalesReps: hideFromSalesReps,
			customAccountsSelected: selectionType,
			customProductsSelected: selectionTypeProd,
		};

		if (item.type === "quantity") {
			updatedItem = {
				...updatedItem,
				measure: getMeasuresForSave(measures),
			};
		}
		if (item.type !== "multi") {
			updatedItem = {
				...updatedItem,
				quantityPayout: getPayoutType(payoutDetails) || 0,
			};
		}

		if (item.type === "imageValidation") {
			updatedItem = {
				...updatedItem,
				minQuantityValue: minQuantityValue,
			};
		}

		if (item.type === "grouped") {
			const itemsToSend =
				selectedGroupedItems && selectedGroupedItems.length > 0
					? selectedGroupedItems
					: groupedChildren.map((obj) => ({
						id: obj._id,
						weight: obj.weight != null ? obj.weight : 0,
					}));
			if (
				selectedGroupedItems?.length === 0 &&
				groupedChildren.length > 0
			) {
				addGroupedItems([], item._id);
			} else {
				addGroupedItems(itemsToSend, item._id);
			}
			updatedItem = {
				...updatedItem,
				advancedBudget: payoutDetails.advancedBudget,
				advancedPayments: payoutDetails.advancedPayments,
			};
		}
		
		onSave(updatedItem);
	};



	return(
		<FormProvider {...methods}>
			<div className={classes.modalHeight}>
				<DialogContent className={classes.dialogContent}>
					<Typography
						align="center"
						variant="h6"
						className={classes.dialogTitle}
					>
						{capitalizeFirstLetter(item.type)} {isObjective ? "Objective" : "Incentive"}
					</Typography>
					<NameDescriptionPeriodComponent
						item={item}
						handleChange={handleChange}
					/>
					<TagComponent
						selectedTagId={tag}
						onTagChange={handleTagChange}
					/>
					{item.type === ObjectiveTypeEnum.Grouped && (
						<DividerFormControl>
							<GroupedTable
								itemType={isObjective ? "objective" : "incentive"}
								filteredItems={filteredItems}
								groupedItems={groupedChildren}
								onGroupedItemsChange={handleChangeGroupedItems}
								filteredData={{
									currentFilteredPage: currentPage,
									loadingFiltered: loading,
									totalFilteredPages: totalPages,
									setCurrentFilteredPage: setCurrentPage,
								}}
								groupedData={{
									totalGroupedPages,
									loadingGrouped,
									setCurrentGroupedPage,
									currentGroupedPage,
								}}
							/>
						</DividerFormControl>
					)}
					{item.type !== ObjectiveTypeEnum.Multi && (
						<>
							<TargetComponent
								name={"Target"}
								target={payoutDetails?.target || 0}
								isOpenEnded={item.isOpenEnded}
								onTargetChange={handleTargetChange}
								onIsOpenEndedChange={handleIsOpenEndedChange}
								itemType={item.type}
								isAccountSpecific={item.isAccountSpecific}
								onIsAccountSpecificChange={handleIsAccountSpecificChange}
								isGrouped={item.isGrouped}
							/>
						</>
					)}
					{item.type === ObjectiveTypeEnum.ImageValidation && (
						<MinimumQuantityComponent
							minQuantityValue={minQuantityValue}
							onMinQuantityValueChange={handleMinQuantityValueChange}
						/>
					)}
					{item.type === ObjectiveTypeEnum.Multi && (
						<>
							<MultiPayoutComponent
								subItems={localSubItems}
								onPayoutChange={handleMultiPayoutChange}
							/>
						</>
					)}
					{item.type === ObjectiveTypeEnum.Quantity && (
						<>
							<Divider className={classes.divider} />
							<QuantityForm
								measures={measures}
								handleMeasureChange={handleMeasureChange}
								customPeriodStart={item.customPeriodStart}
								customPeriodEnd={item.customPeriodEnd}
							/>
						</>
					)}
					{item.type !== ObjectiveTypeEnum.Multi && (
						<>
							<Divider className={classes.divider} />
							<div className={classes.flexColumn}>
								<PayoutComponent
									payoutDetails={payoutDetails}
									setPayoutDetails={setPayoutDetails}
									itemType={item.type}
								/>
							</div>
						</>
					)}

					{item.type === ObjectiveTypeEnum.Validation && (
						<DividerFormControl>
							<div className={classes.validationObjContainer}>
								<div>
									<ValueInput
										valueInputType={item.valueInputType}
										valueInput={item.valueInput}
										handleChange={handleValueInputChange}
									/>
								</div>
							</div>
						</DividerFormControl>
					)}

					{!isObjective && "supplierContribution" in item && (
						<DividerFormControl>
							<FormLabel className={classes.spacedCenterAlign}>
								Supplier Contribution: %
							</FormLabel>
							<TextField
								margin="dense"
								id="supplierContribution"
								variant="outlined"
								type="number"
								fullWidth
								name="supplierContribution"
								value={item.supplierContribution}
								className={classes.flex2}
								onChange={(event) =>
									handleChange("supplierContribution", event.target.value)
								}
							/>
						</DividerFormControl>
					)}
					<DividerFormControl>
						<div className={classes.accordionsContainer}>
							{item.type !== ObjectiveTypeEnum.Multi &&
								item.type !== ObjectiveTypeEnum.Grouped && (
									<>
										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMore />}
												aria-controls="panel1-content"
												id="panel1-header"
												className={classes.accordeonFontSize}
											>
												Accounts:
											</AccordionSummary>
											<AccordionDetails className={classes.flexColumn}>
												<SelectionTabs
													selectionType={selectionType}
													setSelectionType={handleTabChange}
												/>

												{selectionType && (
													<CustomAccountsSelection
														selectedAccountsFilters={selectedAccountsFilters}
														setSelectedAccountsFilters={
															setSelectedAccountsFilters
														}
														item={item}
													/>
												)}
												{!selectionType && (
													<ManualAccountsSelection item={initialItemState} />
												)}
											</AccordionDetails>
										</Accordion>

										<Accordion>
											<AccordionSummary
												expandIcon={<ExpandMore />}
												aria-controls="panel1-content"
												id="panel1-header"
												className={classes.accordeonFontSize}
											>
												Products:
											</AccordionSummary>
											<AccordionDetails className={classes.flexColumn}>
												<SelectionTabs
													selectionType={selectionTypeProd}
													setSelectionType={handleProdTabChange}
												/>
												{selectionTypeProd && (
													<CustomProductsSelection
														selectedProductsFilters={selectedProductsFilters}
														setSelectedProductsFilters={
															setSelectedProductsFilters
														}
														item={item}
													/>
												)}
												{!selectionTypeProd && (
													<ManualProductsSelection item={initialItemState} />
												)}
											</AccordionDetails>
										</Accordion>
									</>
								)}
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1-content"
									id="panel1-header"
									className={classes.accordeonFontSize}
								>
									Users:
								</AccordionSummary>
								<AccordionDetails className={classes.flexColumn}>
									<CustomUsersSelection
										initialSelectedUserIds={selectedUsers}
										onChange={handleUserSelectionChange}
										hideFromSalesReps={hideFromSalesReps}
										onCheckChange={handleHideFromSalesRepsChange}
									/>
								</AccordionDetails>
							</Accordion>
						</div>
					</DividerFormControl>
					{item.type === "multi" && (
						<>
							<div className={classes.buttonContainer}>
								<Button
									className={classes.button}
									color="primary"
									startIcon={<AddCircleOutlineIcon />}
									onClick={handleClickOpen}
								>
									New Sub-{isObjective ? "Objective" : "Incentive"}
								</Button>
							</div>

							<AddNewSubItemComponent
								open={open}
								initState={isObjective ? { ...initialSubObj } : { ...initialSubInc }}
								itemType={isObjective ? "objective" : "incentive"}
								onSave={(item: SemiItem, parentItemId: string) =>
									handleAddSubItem(
										item as Omit<Objective | Incentive, "_id">,
										parentItemId
									)
								}
								onClose={() => setOpen(false)}
								parentItemId={item._id}
							/>
							<SubItemList
								subItems={localSubItems}
								onEdit={handleEditSubItem}
								onDuplicate={handleDuplicateSubItem}
								onDelete={handleDeleteSubItem}
								onReorder={handleReorderSubItems}
							/>
						</>
					)}
					{!subItemLoading && subItem && (
						<EditSubItemComponent
							initialSubItem={subItem}
							open={isEditSubItemModalOpen}
							onSave={handleSaveSubItem}
							onClose={() => setIsEditSubItemModalOpen(false)}
							itemType={isObjective ? "objective" : "incentive"}
							parentUsers={item.users}
							subItemLoading={subItemLoading}
						/>
					)}
				</DialogContent>
				<div className={classes.buttonsWrapper}>
					<Button onClick={onClose} color="primary">
						Cancel
					</Button>
					<Button
						type="submit"
						onClick={methods.handleSubmit(handleItemSubmit)}
						color="primary"
					>
						Save
					</Button>
				</div>
			</div>
		</FormProvider>
	)
};

export default EditItemComponent;