import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((_) => ({
	container: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
	},
	dialogContent: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "55vw",
		height: "80vh",
		overflow: "auto",
	},
	listWrapper: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		minHeight: "46rem",
	},
	itemList: {
		flex: 1,
		maxWidth: "100%",
		fontSize: 9,
		marginBottom: "1rem",
		maxHeight: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	editModal: {
		flex: 1,
	},
	buttonContainer: {},
	button: {
		marginBottom: "1rem",
	},
	buttonAndSearchContainer: {
		display: "flex",
		width: "100%",
		gap: "1vw",
		marginTop: "0.3rem",
		marginBottom: "0.3rem",
	},
	searchContainer: {},
	filterContainer: {
		display: "flex",
		flexDirection: "row",
		gap: "1rem",
		width: "40vw",
	},
	datePickerContainer: {
		display: "flex",
		flexDirection: "row",
		gap: "1rem",
	},
	loadingContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		width: "100%",
		minHeight: "300px",
	},
}));
