import React, { useState } from "react";
import { Button, CircularProgress, Dialog, TextField } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useStyles } from "./ItemComponent.styles";
import { Objective } from "../types/objectiveTypes";
import ItemList from "../objectivesIncentivesComponent/itemsList/ItemsList";
import { Incentive } from "../types/incentivesTypes";
import { AddNewItemComponent } from "../objectivesIncentivesComponent/addObjectiveComponents/addNewItem";
import Select from "react-select";
import moment from "moment";
import useItem from "../hooks/useItem";
import EditItemComponent from "../objectivesIncentivesComponent/editObjIncComponents/EditItemComponent";

export type Item = Objective | Incentive;

interface ItemComponentProps {
	items: Item[];
	itemType: "objective" | "incentive";
	showAddButton?: boolean;
	showDeleteButton?: boolean;
	currentPage?: number;
	loading: boolean;
	setCurrentPage: (page: number) => void;
	totalPages?: number;
	onAdd: (
		data: Omit<Item, "_id">
	) => Promise<Objective | Incentive | undefined>;
	onEdit: (id: string, data: Partial<Item>) => void;
	onDelete: (id: string) => void;
	onFavorite: (id: string) => void;
	onDuplicate: (id: string) => void;
	onArchive: (id: string) => void;
	onAction: (id: string) => void;
	onReset: (id: string) => void;
	searchTerm?: string;
	onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	tags?: any[];
	selectedTag?: string;
	onTagChange?: (event: any) => void;
	periodStart?: any;
	periodEnd?: any;
	onPeriodStartChange?: (event: any) => void;
	onPeriodEndChange?: (event: any) => void;
	selectedType?: string,
	onTypeChange?: (event: any) => void;
}

const ItemComponent: React.FC<ItemComponentProps> = ({
	items,
	itemType,
	loading,
	currentPage,
	totalPages,
	showAddButton = true,
	showDeleteButton = true,
	onAdd,
	onEdit,
	onDelete,
	onFavorite,
	onDuplicate,
	onArchive,
	setCurrentPage,
	onAction,
	onReset,
	searchTerm,
	onSearchChange,
	tags,
	selectedTag,
	onTagChange,
	periodStart,
	periodEnd,
	onPeriodStartChange,
	onPeriodEndChange,
	selectedType,
	onTypeChange
}) => {
	const classes = useStyles();

	const [open, setOpen] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [editableItemId, setEditableItemId] = useState<string | undefined>(undefined);

	const { item, subItems, itemLoading } = useItem({
		itemId: editableItemId,
		isObjective: itemType === "objective" ? true : false,
	});


	const filterOptions = [
		{ label: "Binary", value: "binary" },
		{ label: "Image", value: "image" },
		{ label: "Quantity", value: "quantity" },
		{ label: "Multi", value: "multi" },
		{ label: "Validation", value: "validation" },
		{ label: "Grouped", value: "grouped" },
		{ label: "Image Validation", value: "imageValidation" },
	];

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleSaveEditItem = (item: Item) => {
		setOpenEditModal(false);
		onEdit(item._id, item);
		setEditableItemId(undefined)
	};

	const handleEditItem = (id: string) => {
		if (openEditModal) {
			setOpenEditModal(false);
			setEditableItemId(undefined);
		}
		if(id){
			setEditableItemId(id);
			setOpenEditModal(true);
		}
		
	};

	const handleAddItem = async (newItemData: Omit<Item, "_id">) => {
		const newItem = await onAdd(newItemData);
		if (newItem && newItem._id) {
			setEditableItemId(newItem._id);
			setOpenEditModal(true);
		}
		return newItem;
	};
	
	return (
		<div className={classes.container}>
			<div className={classes.buttonAndSearchContainer}>
				{showAddButton && (
					<div className={classes.buttonContainer}>
						<Button
							className={classes.button}
							color="primary"
							startIcon={<AddCircleOutlineIcon />}
							onClick={handleClickOpen}
						>
							New{" "}
							{itemType === "objective"
								? "Objective"
								: "Incentive"}
						</Button>
					</div>
				)}
				<div className={classes.searchContainer}>
					<TextField
						label="Search"
						value={searchTerm}
						onChange={onSearchChange}
						variant="outlined"
						size="small"
					/>
				</div>
				<div className={classes.filterContainer}>
					<Select
						value={
							selectedType
								? {
									value: selectedType,
									label: filterOptions.find(
										(option) => option.value === selectedType
									)?.label,
								}
								: null
						}
						options={filterOptions}
						onChange={onTypeChange}
						placeholder="Select a type"
						styles={{
							menuPortal: (provided) => ({
								...provided,
								zIndex: 9999,
							}),
							control: (provided) => ({
								...provided,
								width: "200px"
							}),
						}}
						menuPortalTarget={document.body}
					/>
					<Select
						value={
							tags?.find((tag) => tag.value === selectedTag)
								? {
										value: selectedTag,
										label: tags.find(
											(tag) => tag.value === selectedTag
										)?.label,
								  }
								: null
						}
						options={tags?.map((tag) => ({
							value: tag.value,
							label: tag.label,
						}))}
						onChange={onTagChange}
						placeholder="Select a tag"
						styles={{
							menuPortal: (provided) => ({
								...provided,
								zIndex: 9999,
							}),
							control: (provided) => ({
								...provided,
								width: "200px"
							}),
						}}
						menuPortalTarget={document.body}
					/>
					<div className={classes.datePickerContainer}>
						<TextField
							label="Start Period"
							type="date"
							value={periodStart ? moment(periodStart).format("YYYY-MM-DD") : ""}
							onChange={onPeriodStartChange}
							variant="outlined"
							size="small"
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							label="End Period"
							type="date"
							value={periodEnd ? moment(periodEnd).format("YYYY-MM-DD") : ""}
							onChange={onPeriodEndChange}
							variant="outlined"
							size="small"
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>
				</div>
			</div>
			{showAddButton && (
				<AddNewItemComponent
					open={open}
					itemType={itemType}
					onAdd={handleAddItem}
					onClose={() => setOpen(false)}
				/>
			)}

			<div className={classes.listWrapper}>
				<div className={classes.itemList}>
					<ItemList
						items={items}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						totalPages={totalPages}
						loading={loading}
						showDeleteButton={showDeleteButton}
						onDelete={onDelete}
						onEdit={handleEditItem}
						onFavorite={onFavorite}
						onDuplicate={onDuplicate}
						onArchive={onArchive}
						onAction={onAction}
						onReset={onReset}
						itemType={itemType}
					/>
				</div>
				{openEditModal && (
					<Dialog
						open={openEditModal}
						onClose={() => setOpenEditModal(false)}
						fullWidth
						disableEnforceFocus
						PaperProps={{
							style: {
								position: "absolute",
								right: 0,
								minWidth: "55vw",
								maxHeight: "100vh",
								height: "100%",
								margin: 0,
								borderRadius: 0,
							},
						}}
					>
						{itemLoading ? (
							<div className={classes.loadingContainer}>
								<CircularProgress />
							</div>
						) : item ? (
								<EditItemComponent
									key={item._id}
									isObjective={itemType === "objective" ? true : false}
									initialItemState={item}
									subItems={subItems}
									onSave={handleSaveEditItem}
									onClose={() => setOpenEditModal(false)}
								/>
							) : null}
					</Dialog>
				)}
			</div>
		</div>
	);
};

export default ItemComponent;
