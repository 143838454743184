import useQuery, { IGeneralQueryItem } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";
import { Incentive } from "../types/incentivesTypes";
import { Objective } from "../types/objectiveTypes";

const useHandleItemsUpdate = ({ itemType, isObjective }: { itemType: string; isObjective: boolean }) => {
	const { query } = useQuery<IGeneralQueryItem>();

	const { wholesalerId } = query;

	

	const addItem = async (
		newItem: Omit<Objective | Incentive, "_id">,
		parentId?: string
	) => {
		const dynamicQuery: IGeneralQueryItem = {
			wholesalerId,
			parentId,
			...(isObjective ? { isObjective: true } : { isIncentive: true }),
		};

		const obj = await commonObjIncStoreZustand
			.getState()
			.addItem(dynamicQuery, { ...newItem });

		return obj;
	};

	const editItem = (
		itemId: string,
		updatedFields: Partial<Objective | Incentive>,
		isGrouped?: boolean,
	) => {
		const dynamicQuery: IGeneralQueryItem = {
			wholesalerId,
			...(isObjective ? { isObjective: true } : { isIncentive: true }),
		};

		commonObjIncStoreZustand
			.getState()
			.editItem(
				dynamicQuery,
				{ _id: itemId, ...updatedFields },
				isGrouped
			);
	};

	const deleteItem = (itemId: string) => {
		commonObjIncStoreZustand.getState().deleteItem(query, itemId, itemType);
	};

	const runQuery = (itemId: string) => {
		commonObjIncStoreZustand
			.getState()
			.runQuery(query, itemId, itemType);
	};

	const resetValues = (itemId: string) => {
		commonObjIncStoreZustand
			.getState()
			.resetValues(query, itemId, itemType);
	};


	return {
		addItem,
		editItem,
		deleteItem,
		runQuery,
		resetValues,
	};
};

export default useHandleItemsUpdate;