import { useEffect } from "react";
import useQuery, { IGeneralQueryItem } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useSubItem = ({
	subItemId,
	isObjective,
	parentItemId,
}: {
	subItemId?: string;
	isObjective?: boolean;
	parentItemId?: string;
}) => {
	const { query } = useQuery<IGeneralQueryItem>();
	const { wholesalerId } = query;

	const subItem = commonObjIncStoreZustand((state) => state.semiItem);
	const subItemLoading = commonObjIncStoreZustand(
		(state) => state.semiItemLoading
	);

	useEffect(() => {
		if (subItemId) {
			const dynamicQuery: IGeneralQueryItem = {
				wholesalerId,
				...(isObjective
					? { isObjective: true }
					: { isIncentive: true }),
				...(parentItemId
					? {
							parentObjectiveId: isObjective
								? parentItemId
								: undefined,
							parentIncentiveId: !isObjective
								? parentItemId
								: undefined,
					  }
					: {}),
			};

			commonObjIncStoreZustand.getState().fetchSemiItem(dynamicQuery, subItemId);
		}
	}, [wholesalerId, subItemId, isObjective, parentItemId]);

	return { subItem, subItemLoading };
};

export default useSubItem;
