import { useEffect, useState } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";
import { debounce } from "lodash";


const useItems = ({
	searchPhrase,
	type,
	tag,
	periodStart,
	periodEnd,
	isObjective,
	isArchived,
	isFavorite,
}: {
	searchPhrase?: string;
	type?: string;
	tag?: string;
	periodStart?: any;
	periodEnd?: any;
	itemsType?: string;
	isObjective?: boolean;
	isArchived?: boolean;
	isFavorite?: boolean;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();

	const { wholesalerId } = query;
	const [pageSize, setPageSize] = useState(15);
	const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState<
		string | undefined
	>(searchPhrase);

	const items = commonObjIncStoreZustand((state) => state.items);

	const loading = commonObjIncStoreZustand((state) => state.loading);
	const currentPage = commonObjIncStoreZustand(
		(state) => state.itemsCurrentPage
	);
	const setCurrentPage = commonObjIncStoreZustand(
		(state) => state.setItemsCurrentPage
	);
	const totalPages = commonObjIncStoreZustand(
		(state) => state.itemsTotalPages
	);

	useEffect(() => {
		const handler = debounce(() => {
			setDebouncedSearchPhrase(searchPhrase);
		}, 300);

		handler();

		return () => {
			handler.cancel();
		};
	}, [searchPhrase]);

	useEffect(() => {
		if (searchPhrase) {
			setCurrentPage(1);
		}
	}, [searchPhrase, setCurrentPage]);

	useEffect(() => {
		commonObjIncStoreZustand.getState().fetchItems(
			{
				wholesalerId,
				page: currentPage,
				limit: pageSize,
				searchPhrase: debouncedSearchPhrase,
				type,
				tag,
				periodStart,
				periodEnd,
				...(isObjective
					? { isObjective: true }
					: { isIncentive: true }),
				...(isArchived !== undefined ? { isArchived } : {}),
				...(isFavorite !== undefined ? { isFavorite } : {}),
			},
		);
	}, [
		wholesalerId,
		currentPage,
		pageSize,
		debouncedSearchPhrase,
		type,
		tag,
		periodStart,
		periodEnd,
		isObjective,
		isArchived,
		isFavorite,
	]);

	return {
		items,
		currentPage,
		loading,
		totalPages,
		setCurrentPage,
		setPageSize,
	};
};

export default useItems;