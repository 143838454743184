import { useEffect } from "react";
import useQuery, { IGeneralQueryItem } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useItem = ({
	itemId,
	isObjective,
	isArchived,
	isFavorite,
}: {
	itemId?: string;
	isObjective?: boolean;
	isArchived?: boolean;
	isFavorite?: boolean;
}) => {
	const { query } = useQuery<IGeneralQueryItem>();
	const { wholesalerId } = query;

	const item = commonObjIncStoreZustand((state) => state.item);
	const subItems = commonObjIncStoreZustand((state) => state.subItems);
	const itemLoading = commonObjIncStoreZustand((state) => state.itemLoading);

	useEffect(() => {
		if (itemId) {
			const dynamicQuery: IGeneralQueryItem = {
				wholesalerId,
				...(isObjective
					? { isObjective: true }
					: { isIncentive: true }),
				...(isArchived !== undefined ? { isArchived } : {}),
				...(isFavorite !== undefined ? { isFavorite } : {}),
			};

			commonObjIncStoreZustand.getState().fetchItem(dynamicQuery, itemId);
		}
	}, [wholesalerId, itemId, isObjective, isArchived, isFavorite]);

	return { item, subItems, itemLoading };
};

export default useItem;
