import React, { useState } from "react";
import {
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
	TableContainer,
	Paper,
} from "@material-ui/core";
import { Item } from "../../types/commonObjIncTypes";
import { ItemDisplayComponent } from "./ItemDisplayComponent";
import { useStyles } from "./ItemsList.styles";
import Pagination from "@material-ui/lab/Pagination";
import useTags from "../../hooks/useTags";

interface ObjectiveListProps {
	items: Item[];
	currentPage?: number;
	totalPages?: number;
	loading?: boolean;
	setCurrentPage: (page: number) => void;
	showDeleteButton?: boolean;
	onDelete: (id: string) => void;
	onEdit: (id: string) => void;
	onDuplicate: (id: string) => void;
	onFavorite: (id: string) => void;
	onArchive: (id: string) => void;
	onAction: (id: string) => void;
	onReset: (id: string) => void;
	itemType: string;
}

export const ItemList: React.FC<ObjectiveListProps> = ({
	items,
	currentPage,
	totalPages,
	setCurrentPage,
	showDeleteButton = true,
	loading,
	onDelete,
	onEdit,
	onDuplicate,
	onFavorite,
	onArchive,
	onAction,
	onReset,
	itemType,
}) => {
	const classes = useStyles();
	const { tags } = useTags();

	const type = itemType === "objective" ? "objectives" : "incentives";

	const [sortConfig, setSortConfig] = useState<{
		key: keyof Item;
		direction: "asc" | "desc";
	} | null>(null);

	const sortedItems = [...items].sort((a, b) => {
		if (!sortConfig) return 0;

		const { key, direction } = sortConfig;
		let aValue, bValue;

		if (key === "period") {
			aValue = a.customPeriodStart ? new Date(a.customPeriodStart) : null;
			bValue = b.customPeriodStart ? new Date(b.customPeriodStart) : null;

			if (aValue === null && bValue === null) return 0;
			if (aValue === null) return direction === "asc" ? -1 : 1;
			if (bValue === null) return direction === "asc" ? 1 : -1;
		} else if (key === "measure") {
			aValue = a.measure?.label ?? "-";
			bValue = b.measure?.label ?? "-";
		} else {
			aValue =
				typeof a[key] === "string"
					? (a[key] as string).toLowerCase().replace(/\s+/g, "")
					: a[key] ?? "";
			bValue =
				typeof b[key] === "string"
					? (b[key] as string).toLowerCase().replace(/\s+/g, "")
					: b[key] ?? "";
		}

		if (aValue === "-") return 1;
		if (bValue === "-") return -1;

		return direction === "asc"
			? aValue < bValue
				? -1
				: 1
			: aValue > bValue
			? -1
			: 1;
	});

	const handleSort = (key: keyof Item) => {
		setSortConfig((prev) => {
			if (prev && prev.key === key) {
				return {
					key,
					direction: prev.direction === "asc" ? "desc" : "asc",
				};
			}
			return { key, direction: "desc" };
		});
	};

	return (
		<Paper className={classes.tableContainer}>
			<TableContainer className={classes.tableWrapper}>
				<Table stickyHeader className={classes.customTable}>
					<TableHead>
						<TableRow className={classes.tableHead}>
							<TableCell className={classes.customCell}>
								<TableSortLabel
									active={sortConfig?.key === "name"}
									direction={
										sortConfig?.key === "name" ? sortConfig.direction : "asc"
									}
									onClick={() => handleSort("name")}
								>
									Name
								</TableSortLabel>
							</TableCell>
							<TableCell className={classes.customCell}>
								<TableSortLabel
									active={sortConfig?.key === "tag"}
									direction={
										sortConfig?.key === "tag" ? sortConfig.direction : "asc"
									}
									onClick={() => handleSort("tag")}
								>
									Tag
								</TableSortLabel>
							</TableCell>
							<TableCell className={classes.customCell}>
								<TableSortLabel
									active={sortConfig?.key === "type"}
									direction={
										sortConfig?.key === "type" ? sortConfig.direction : "asc"
									}
									onClick={() => handleSort("type")}
								>
									Type
								</TableSortLabel>
							</TableCell>
							<TableCell className={classes.customCell}>
								<TableSortLabel
									active={sortConfig?.key === "measure"}
									direction={
										sortConfig?.key === "measure" ? sortConfig.direction : "asc"
									}
									onClick={() => handleSort("measure")}
								>
									Measure
								</TableSortLabel>
							</TableCell>
							<TableCell className={classes.customCell}>
								<TableSortLabel
									active={sortConfig?.key === "period"}
									direction={
										sortConfig?.key === "period" ? sortConfig.direction : "asc"
									}
									onClick={() => handleSort("period")}
								>
									Period
								</TableSortLabel>
							</TableCell>
							<TableCell
								className={[
									`${classes.customCell}`,
									`${classes.actionsButtons}`,
								].join(" ")}
							>
								Actions
							</TableCell>
						</TableRow>
					</TableHead>
					{!loading && items.length === 0 ? (
						<TableBody>
							<TableRow>
								<TableCell colSpan={6} align="center">
									<Typography className={classes.notFound} variant="body1">
										No {type} found
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					) : (
						!loading && (
							<TableBody>
								{sortedItems.map((item) => {
									const itemTag = item.tag
										? tags.find((tag) => tag._id === item.tag)
										: null;
									const tagProps = itemTag
										? { name: itemTag.name, color: itemTag.color }
										: undefined;

									return (
										<ItemDisplayComponent
											key={item._id}
											item={item}
											tag={tagProps}
											showDeleteButton={showDeleteButton}
											onDelete={onDelete}
											onEdit={() => onEdit(item._id)}
											onFavorite={() => onFavorite(item._id)}
											onDuplicate={() => onDuplicate(item._id)}
											onArchive={() => onArchive(item._id)}
											showActionQueryButton={
												item.type === "quantity" ||
												item.type === "multi" ||
												item.type === "grouped"
											}
											onAction={() => onAction(item._id)}
											onReset={() => onReset(item._id)}
										/>
									);
								})}
							</TableBody>
						)
					)}
				</Table>
			</TableContainer>

			<div className={classes.footer}>
				{loading ? (
					<CircularProgress size={24} />
				) : (
					<Typography variant="body2"></Typography>
				)}
				<Pagination
					count={totalPages}
					page={currentPage}
					onChange={(_, value) => setCurrentPage(value)}
					color="primary"
					size="small"
				/>
			</div>
		</Paper>
	);
};

export default ItemList;
