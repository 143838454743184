import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useToRemoveTeamMembers = ({ userIds }: { userIds?: string[] }) => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	const superTeamMembersToRemove = commonObjIncStoreZustand(
		(state) => state.superTeamMembersToRemove
	);

	const removeSuperMembers = async (userIds: string[]) => {
		const members = commonObjIncStoreZustand
			.getState()
			.fetchSuperTeamMembersToRemove({ wholesalerId }, userIds);
		return members;
	};

	return {
		superTeamMembersToRemove,
		removeSuperMembers,
	};
};

export default useToRemoveTeamMembers;
