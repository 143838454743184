import { useEffect } from "react";
import useQuery, { IGeneralQueryItem } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useChildItem = ({
	childItemId,
	isObjective,
}: {
	childItemId?: string;
	isObjective?: boolean;
}) => {
	const { query } = useQuery<IGeneralQueryItem>();
	const { wholesalerId } = query;

	const childItem = commonObjIncStoreZustand((state) => state.semiItem);
	const childItemLoading = commonObjIncStoreZustand(
		(state) => state.semiItemLoading
	);

	useEffect(() => {
		if (childItemId) {
			const dynamicQuery: IGeneralQueryItem = {
				wholesalerId,
				...(isObjective
					? { isObjective: true }
					: { isIncentive: true }),
			};

			commonObjIncStoreZustand
				.getState()
				.fetchSemiItem(dynamicQuery, childItemId);
		}
	}, [wholesalerId, childItemId, isObjective]);

	return { childItem, childItemLoading };
};

export default useChildItem;
