import React, { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from "@material-ui/core";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from "react-beautiful-dnd";
import { Edit, Delete, FileCopy, DragIndicator } from "@material-ui/icons";
import { Objective } from "../../types/objectiveTypes";
import { Incentive } from "../../types/incentivesTypes";
import { useStyles } from "./ItemsList.styles";
import { isIncentive } from "../../types/commonObjIncTypes";

interface SubItemListProps {
	subItems?: (Objective | Incentive)[] | null;
	onDelete: (id: string, parentId: string) => void;
	onEdit: (id: string) => void;
	onDuplicate: (id: string, parentObjectiveId: string) => void;
	onReorder: (reorderedItems: (Objective | Incentive)[]) => void;
}

export const SubItemList: React.FC<SubItemListProps> = ({
	subItems,
	onDelete,
	onDuplicate,
	onEdit,
	onReorder,
}) => {
	const classes = useStyles();
	const [deleteItem, setDeleteItem] = useState<{
		id: string;
		parentId: string;
	} | null>(null);

	if (!subItems) {
		return null;
	}

	// Sort subItems by order
	const sortedSubItems = [...subItems].sort(
		(a, b) => (a.order ?? 0) - (b.order ?? 0)
	);

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const items = Array.from(sortedSubItems);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		// Update order property for each item
		const updatedItems = items.map((item, index) => ({
			...item,
			order: index + 1,
		}));

		onReorder(updatedItems);
	};

	const handleDeleteClick = (id: string, parentId: string) => {
		setDeleteItem({ id, parentId });
	};

	const handleDeleteConfirm = () => {
		if (deleteItem) {
			onDelete(deleteItem.id, deleteItem.parentId);
			setDeleteItem(null);
		}
	};

	const handleDeleteCancel = () => {
		setDeleteItem(null);
	};

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="subItems">
					{(provided) => (
						<Table
							className={classes.customTable}
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							<TableHead>
								<TableRow>
									<TableCell style={{ width: "40px" }}></TableCell>
									<TableCell>Name</TableCell>
									<TableCell>Type</TableCell>
									<TableCell align="right">Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{sortedSubItems.map((subItem, index) => {
									const parentId = !isIncentive(subItem)
										? subItem.parentObjectiveId
										: subItem.parentIncentiveId;

									return (
										<Draggable
											key={subItem._id}
											draggableId={subItem._id}
											index={index}
										>
											{(provided) => (
												<TableRow
													ref={provided.innerRef}
													{...provided.draggableProps}
													className={classes.customRow}
												>
													<TableCell {...provided.dragHandleProps}>
														<DragIndicator />
													</TableCell>
													<TableCell>{subItem.name}</TableCell>
													<TableCell>{subItem.type}</TableCell>
													<TableCell align="right">
														<IconButton
															onClick={() => onEdit(subItem._id)}
															size="small"
															color="primary"
														>
															<Edit />
														</IconButton>
														<IconButton
															onClick={() =>
																parentId &&
																handleDeleteClick(subItem._id, parentId)
															}
															size="small"
														>
															<Delete />
														</IconButton>
														<IconButton
															onClick={() =>
																parentId && onDuplicate(subItem._id, parentId)
															}
															size="small"
															color="primary"
														>
															<FileCopy />
														</IconButton>
													</TableCell>
												</TableRow>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</TableBody>
						</Table>
					)}
				</Droppable>
			</DragDropContext>

			<Dialog
				open={deleteItem !== null}
				onClose={handleDeleteCancel}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this item?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteConfirm} color="primary" autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
