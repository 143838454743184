import { useEffect } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useSuperTeamMembers = ({ userId }: { userId?: string }) => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	const superTeamMembers = commonObjIncStoreZustand(
		(state) => state.superTeamMembers
	);

	useEffect(() => {
		commonObjIncStoreZustand
			.getState()
			.fetchSuperTeamMembers({ wholesalerId }, userId);
	}, [wholesalerId, userId]);

	return {
		superTeamMembers,
	};
};

export default useSuperTeamMembers;
