import { create } from "zustand";
import api from "../../../utils/api";
import { ISortDirection, incColumns } from "../utils/types";
import {
	IGeneralQuery,
	IGeneralQueryWithCopy,
	IQueryFavorite,
	IQueryFavoriteWithSearch,
	IQueryFiltered,
} from "../../../hooks/useQuery";
import snack from "../../../utils/snack";
import { IBuyer, IUser } from "../../../interfaces";
import { IWholeSaler } from "../../usersManagement/types/types";
import {
	AdvancedPayment,
	Incentive,
} from "../../usersManagement/types/incentivesTypes";
import { ObjIncentiveImage } from "../../usersManagement/types/commonObjIncTypes";

interface Store {
	incentives: Incentive[];
	filteredIncentives: Incentive[];
	groupedIncentives: Incentive[];
	totalPages: number;
	totalFilteredPages: number;
	currentFilteredPage: number;
	loadingFiltered: boolean;
	totalGroupedPages: number;
	currentGroupedPage: number;
	loadingGrouped: boolean;
	currentPage: number;
	secondaryCurrentPage: number;
	reportingGroupedPage: number;
	secondaryTotalPages: number;
	reportingGroupedTotalPages: number;
	setSecondaryCurrentPage: (page: number) => void;
	setReportingGroupedPage: (page: number) => void;
	archivedTotalPages: number;
	archivedCurrentPage: number;
	archivedSecondaryCurrentPage: number;
	archivedSecondaryTotalPages: number;
	setArchivedSecondaryCurrentPage: (page: number) => void;
	loadingArchived: boolean;
	archivedIncentives: Incentive[];
	favoriteIncentives: Incentive[];
	totalFavPages: number;
	currentFavPage: number;
	accountTypes: string[];
	users: IUser[];
	products: IWholeSaler | null;
	customers: IBuyer[] | null;
	setLoadingFiltered: (loading: boolean) => void;
	setLoadingGrouped: (loading: boolean) => void;
	setCurrentFilteredPage: (page: number) => void;
	setCurrentGroupedPage: (page: number) => void;
	fetchFilteredIncentives: (
		query: IQueryFiltered,
		callback?: () => void
	) => void;
	fetchGroupedIncentives: (
		query: IQueryFavorite,
		incentiveId: string
	) => void;
	addGroupedIncentives: (
		query: IGeneralQuery,
		childIds: { id: string; weight: number }[],
		parentIncentiveId: string
	) => Promise<Incentive[]>;
	setCurrentPage: (page: number) => void;
	setCurrentFavPage: (page: number) => void;
	setArchivedCurrentPage: (page: number) => void;
	fetchIncentives: (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => void;
	fetchSubIncentives: (
		query: IGeneralQuery,
		incentiveId: string,
		isArchived?: boolean
	) => void;
	fetchChildren: (query: IGeneralQuery, parentId: string) => void;
	fetchArchivedIncentives: (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => void;
	fetchFavoriteIncentives: (query: IQueryFavoriteWithSearch) => void;
	editIncentive: (query: IGeneralQuery, incentive: Partial<Incentive>) => any;
	editSubIncentive: (
		query: IGeneralQuery,
		incentive: Partial<Incentive>,
		parentId: string,
		suppressSnack: boolean
	) => any;
	columns: string[];
	rows: any[];
	loading: boolean;
	sortColumnName: string | undefined;
	sortDirection: ISortDirection;
	setDirection: (dir: ISortDirection) => void;
	setColumnName: (colName: string) => void;
	setRows: (rows: any[]) => void;
	setLoading: (loading: boolean) => void;
	setLoadingArchived: (loading: boolean) => void;
	hoveredId?: string | number;
	selectedId?: string | number;
	setHoveredId: (id: string | number) => void;
	setSelectedId: (id: string | number) => void;
	setOrder: (dir: ISortDirection, colName: string) => void;
	addIncentive: (
		query: IGeneralQueryWithCopy,
		newIncentive: Omit<Incentive, "_id" | "customId">
	) => Promise<Incentive | undefined>;
	addSubIncentive: (
		query: IGeneralQueryWithCopy,
		newIncentive: Omit<Incentive, "_id" | "customId">,
		parentIncentiveId: string
	) => Promise<Incentive | undefined>;
	deleteIncentive: (query: IGeneralQuery, incentiveId: string) => void;
	deleteSubIncentive: (
		query: IGeneralQuery,
		incentiveId: string,
		parentId: string
	) => Promise<Incentive | undefined>;
	fetchAccountTypes: (query: IGeneralQuery) => void;
	fetchAllUsers: (query: IGeneralQuery) => void;
	fetchProducts: (query: IGeneralQuery) => void;
	fetchCustomers: (query: IGeneralQuery) => void;
	fetchIncentivesReportTable: (
		query: IQueryFavoriteWithSearch,
		callback?: () => void,
		callbackGrouped?: () => void
	) => void;
	runQueryForIncentive: (query: IGeneralQuery, incentiveId: string) => void;
	updateUserTargetAndPayout: (
		query: IQueryFavoriteWithSearch,
		itemId: string,
		userId: string,
		payoutType: string,
		payout: number,
		target: number,
		isOpenEnded: boolean,
		threshold: { units: string; value: number },
		cap: { units: string; value: number },
		minQuantityValue: number,
		isAccountSpecific: boolean,
		isFullAmount: boolean,
		advancedBudget?: number,
		advancedPayments?: AdvancedPayment[],
		filterType?: string
	) => Promise<Incentive[]>;
	resetValuesForIncentive: (
		query: IGeneralQuery,
		incentiveId: string
	) => void;
	rejectImage: (
		query: IGeneralQuery,
		itemId: string,
		imageId: string,
		rejectedReason: string
	) => void;
	unRejectImage: (
		query: IGeneralQuery,
		itemId: string,
		imageId: string
	) => void;
}

export const incentivesStoreZustand = create<Store>((set, get) => ({
	incentives: [],
	filteredIncentives: [],
	groupedIncentives: [],
	totalFilteredPages: 1,
	currentFilteredPage: 1,
	totalGroupedPages: 1,
	currentGroupedPage: 1,
	loadingFiltered: false,
	loadingGrouped: false,
	totalPages: 1,
	currentPage: 1,
	secondaryCurrentPage: 1,
	reportingGroupedPage: 1,
	secondaryTotalPages: 1,
	reportingGroupedTotalPages: 1,
	setSecondaryCurrentPage: (page: number) =>
		set({ secondaryCurrentPage: page }),
	setReportingGroupedPage: (page: number) =>
		set({ reportingGroupedPage: page }),
	archivedTotalPages: 1,
	archivedCurrentPage: 1,
	archivedSecondaryCurrentPage: 1,
	archivedSecondaryTotalPages: 1,
	setArchivedSecondaryCurrentPage: (page: number) =>
		set({ archivedSecondaryCurrentPage: page }),
	loadingArchived: false,
	favoriteIncentives: [],
	totalFavPages: 1,
	currentFavPage: 1,
	archivedIncentives: [],
	accountTypes: [],
	loading: false,
	users: [],
	products: null,
	customers: null,
	hoveredId: undefined,
	columns: incColumns,
	sortColumnName: undefined,
	sortDirection: "asc",
	rows: [],
	setLoadingFiltered: (loadingFiltered: boolean) => set({ loadingFiltered }),
	setLoadingGrouped: (loadingGrouped: boolean) => set({ loadingGrouped }),
	setCurrentFilteredPage: (page: number) =>
		set({ currentFilteredPage: page }),
	setCurrentGroupedPage: (page: number) => set({ currentGroupedPage: page }),
	setCurrentPage: (page: number) => set({ currentPage: page }),
	setCurrentFavPage: (page: number) => set({ currentFavPage: page }),
	setArchivedCurrentPage: (page: number) =>
		set({ archivedCurrentPage: page }),
	setDirection: (dir: ISortDirection) => set({ sortDirection: dir }),
	setRows: (rows: any) => set({ rows }),
	setColumnName: (colName: string) => set({ sortColumnName: colName }),
	setHoveredId: (id: string | number) => set({ hoveredId: id }),
	setSelectedId: (id: string | number) => set({ selectedId: id }),
	setLoading: (loading: boolean) => set({ loading }),
	setLoadingArchived: (loadingArchived: boolean) => set({ loadingArchived }),
	fetchIncentives: async (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => {
		set({ loading: true });
		const res = await api.fetch({
			path: "/api/v2/incentives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ incentives: res.payload, totalPages: res?.meta?.totalPages });
			if (callback) callback();
			set({ loading: false });
		} else {
			console.log("error fetching incentives");
			set({ incentives: [] });
			set({ loading: false });
		}
	},
	fetchFilteredIncentives: async (
		query: IQueryFiltered,
		callback?: () => void
	) => {
		set({ loadingFiltered: true });
		const res = await api.fetch({
			path: "/api/v2/filteredIncentives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				filteredIncentives: res.payload,
				totalFilteredPages: res?.meta?.totalPages,
			});
			if (callback) callback();
			set({ loadingFiltered: false });
		} else {
			console.log("error fetching incentives");
			set({ filteredIncentives: [] });
			set({ loadingFiltered: false });
		}
	},
	fetchSubIncentives: async (
		query: IGeneralQuery,
		incentiveId: string,
		isArchived?: boolean
	) => {
		const res = await api.fetch({
			path: `/api/v2/incentive/${incentiveId}/subIncentives`,
			method: "GET",
			query,
		});

		if (res.ok === true && res.payload?.length > 0) {
			set((state) => {
				const targetIncentivesKey = isArchived
					? "archivedIncentives"
					: "incentives";

				const updatedIncentives = state[targetIncentivesKey].map(
					(incentive) => {
						if (incentive._id === incentiveId) {
							return {
								...incentive,
								subIncentives: res.payload,
							};
						}
						return incentive;
					}
				);

				return { [targetIncentivesKey]: updatedIncentives };
			});
		} else {
			console.log(
				"error fetching subIncentives or no subIncentives found",
				res
			);
		}
	},
	fetchChildren: async (query: IGeneralQuery, parentId: string) => {
		const res = await api.fetch({
			path: `/api/v2/reporting/item/${parentId}`,
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set((state) => {
				const updatedIncentives = state.incentives.map((incentive) => {
					if (incentive._id === parentId) {
						return {
							...incentive,
							groupedIncentives: res.payload.groupedChildren,
						};
					}
					return incentive;
				});

				return { incentives: updatedIncentives };
			});
		} else {
			return;
		}
	},
	fetchGroupedIncentives: async (
		query: IQueryFavorite,
		incentiveId: string
	) => {
		set({ loadingGrouped: true });
		const res = await api.fetch({
			path: `/api/v2/groupedIncentives/${incentiveId}/children`,
			method: "GET",
			query,
		});

		if (res.ok === true && res.payload?.length > 0) {
			set({
				groupedIncentives: res.payload,
				totalGroupedPages: res.meta?.totalPages,
			});
			set({ loadingGrouped: false });
		} else {
			set({ groupedIncentives: [] });
			set({ loadingGrouped: false });
		}
	},
	fetchArchivedIncentives: async (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => {
		set({ loadingArchived: true });
		const res = await api.fetch({
			path: "/incentives/archived",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				archivedIncentives: res.payload,
				archivedTotalPages: res?.meta?.totalPages,
			});
			if (callback) callback();
			set({ loadingArchived: false });
		} else {
			console.log("error fetching incentives");
			set({ archivedIncentives: [] });
			set({ loadingArchived: false });
		}
	},
	fetchFavoriteIncentives: async (query: IQueryFavoriteWithSearch) => {
		const res = await api.fetch({
			path: "/api/v2/incentives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				favoriteIncentives: res.payload,
				totalFavPages: res?.meta?.totalPages,
				currentFavPage: res?.meta?.page,
			});
		} else {
			console.log("error fetching incentives");
			set({ favoriteIncentives: [] });
		}
	},
	addIncentive: async (
		query: IGeneralQueryWithCopy,
		newIncentive: Omit<Incentive, "_id" | "customId">
	) => {
		const res = await api.fetch({
			path: "/incentive",
			method: "POST",
			query,
			body: newIncentive,
		});

		if (res.ok) {
			set((state) => {
				const updatedIncentives = [...state.incentives, res.payload];

				if (res.payload.type === "multi" && query.parentId) {
					const { parentId, ...queryWithoutParentId } = query;

					get().fetchSubIncentives(
						queryWithoutParentId,
						res.payload._id
					);
				}

				return { incentives: updatedIncentives };
			});
			if (query.parentId) {
				snack.success("The incentive has been successfully copied");
			}
			return res.payload;
		} else {
			console.log("error adding incentive");
		}
	},

	addSubIncentive: async (
		query: IGeneralQueryWithCopy,
		newIncentive: Omit<Incentive, "_id" | "customId">,
		parentIncentiveId: string
	) => {
		const res = await api.fetch({
			path: "/subIncentive",
			method: "POST",
			query,
			body: { ...newIncentive, parentIncentiveId },
		});

		console.log("parentIncentiveId", parentIncentiveId);
		if (res.ok) {
			set((state) => {
				// Find the parent incentive and update its subIncentives array
				const updatedIncentives = state.incentives.map((incentive) => {
					if (incentive._id === parentIncentiveId) {
						return {
							...incentive,
							subIncentives: [
								...(incentive.subIncentives || []),
								res.payload,
							],
						};
					}
					return incentive;
				});

				return { incentives: updatedIncentives };
			});
			return res.payload;
		} else {
			console.log("error adding sub-incentive", res);
			return undefined;
		}
	},
	addGroupedIncentives: async (
		query: IGeneralQuery,
		childIds: { id: string; weight: number }[],
		parentIncentiveId: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/addGroupedIncentivesChildren/${parentIncentiveId}`,
			method: "POST",
			query,
			body: { childIds },
		});

		if (res.ok) {
			set((state) => {
				// Find the parent objective and update its groupedObjectives array
				const updatedIncentives = state.incentives.map((incentive) => {
					if (incentive._id === parentIncentiveId) {
						return {
							...incentive,
							groupedIncentives: [
								...(incentive.groupedIncentives || []),
								...(childIds
									.map(({ id }) =>
										state.incentives.find(
											(inc) => inc._id === id
										)
									)
									.filter(Boolean) as Incentive[]),
							],
						};
					}
					return incentive;
				});

				return { ...state, incentives: updatedIncentives };
			});
			return childIds
				.map(({ id }) => get().incentives.find((inc) => inc._id === id))
				.filter(Boolean) as Incentive[];
		} else {
			console.log("error linking grouped incentives", res);
			return [];
		}
	},
	deleteIncentive: async (query: IGeneralQuery, incentiveId: string) => {
		const res = await api.fetch({
			path: `/incentive/${incentiveId}`,
			method: "DELETE",
			query,
		});

		console.log("incentiveId", incentiveId);
		if (res.ok) {
			set((state) => ({
				incentives: [
					...state.incentives.filter(
						(obj) => obj._id !== incentiveId
					),
				],
			}));
		} else {
			console.log("error deleting incentive");
		}
	},
	deleteSubIncentive: async (
		query: IGeneralQuery,
		incentiveId: string,
		parentId: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/subIncentive/${incentiveId}`,
			method: "DELETE",
			query,
		});

		if (res.ok) {
			let updatedParentIncentive = undefined;
			set((state) => {
				const updatedIncentives = state.incentives.map((incentive) => {
					if (incentive._id === parentId) {
						updatedParentIncentive = {
							...incentive,
							subIncentives: incentive.subIncentives?.filter(
								(sub) => sub._id !== incentiveId
							),
						};
						return updatedParentIncentive;
					}
					return incentive;
				});
				return { incentives: updatedIncentives };
			});

			if (updatedParentIncentive) {
				snack.success("Delete completed 👍");
				return updatedParentIncentive;
			} else {
				throw new Error("Parent incentive not found");
			}
		} else {
			snack.error("Delete failed");
		}
	},
	setOrder: (dir: ISortDirection, colName: string) => {
		set((state: Store) => {
			const updatedState: Partial<Store> = {};

			const currentRows = [...state.rows];
			updatedState.rows = currentRows.sort((a, b) => {
				const aVal = a[colName];
				const bVal = b[colName];
				if (aVal > bVal) {
					return dir === "asc" ? -1 : 1;
				} else if (aVal < bVal) {
					return dir === "asc" ? 1 : -1;
				} else {
					return 0;
				}
			});
			return updatedState;
		});
	},
	editIncentive: async (
		query: IGeneralQuery,
		incentive: Partial<Incentive>
	) => {
		snack.info("Updating...");

		const res = await api.fetch({
			path: `/api/v2/incentive/${incentive._id}`,
			method: "PATCH",
			query,
			body: incentive,
		});

		if (res.ok) {
			snack.success("Update completed 👍");
			console.log("Update completed 👍", res.payload);
			set((state) => {
				// Find the index of the incentive being updated
				const incentiveIndex = state.incentives.findIndex(
					(i) => i._id === incentive._id
				);

				// Find the index of the archived incentive being updated
				const archivedIncentiveIndex =
					state.archivedIncentives.findIndex(
						(i) => i._id === incentive._id
					);

				// Preserve existing subincentives or similar data
				const existingSubIncentives =
					incentiveIndex !== -1
						? state.incentives[incentiveIndex].subIncentives
						: null;

				// Assuming res.payload contains the updated incentive without subincentives
				const updatedIncentive =
					incentiveIndex !== -1
						? {
								...state.incentives[incentiveIndex],
								...res.payload, // Merge updated incentive data
								subIncentives: existingSubIncentives, // Keep existing subincentives unchanged
						  }
						: res.payload;

				// Clone the existing incentives array for immutability
				const updatedIncentives =
					incentiveIndex !== -1
						? [...state.incentives]
						: state.incentives;
				if (incentiveIndex !== -1) {
					updatedIncentives[incentiveIndex] = updatedIncentive;
				}

				const updatedArchivedIncentives =
					archivedIncentiveIndex !== -1
						? state.archivedIncentives.map((i) =>
								i._id === incentive._id
									? { ...i, ...res.payload }
									: i
						  )
						: state.archivedIncentives;

				const newArchivedIncentives = res.payload.archived
					? [...updatedArchivedIncentives, res.payload]
					: updatedArchivedIncentives.filter(
							(i) => i._id !== incentive._id
					  );

				const newIncentives = res.payload.archived
					? updatedIncentives.filter((i) => i._id !== incentive._id)
					: updatedIncentives;

				// Return the new state
				return {
					incentives: newIncentives,
					archivedIncentives: newArchivedIncentives,
				};
			});

			return res.payload;
		} else {
			console.log("error updating incentive", res);
		}
	},

	editSubIncentive: async (
		query: IGeneralQuery,
		incentive: Partial<Incentive>,
		parentId: string,
		suppressSnack: boolean
	) => {
		if (!suppressSnack) {
			snack.info("Updating...");
		}

		const res = await api.fetch({
			path: `/api/v2/subIncentive/${incentive._id}`,
			method: "PATCH",
			query,
			body: incentive,
		});
		if (res.ok) {
			set((state) => {
				// Find the parent incentive and update its subIncentives array
				const updatedIncentives = state.incentives.map((obj) => {
					if (obj._id === parentId) {
						return {
							...obj,
							subIncentives: obj.subIncentives?.map((sub) =>
								sub._id === incentive._id ? res.payload : sub
							),
						};
					} else {
						return obj;
					}
				});

				return { incentives: updatedIncentives };
			});
			if (!suppressSnack) {
				snack.success("Update completed 👍");
			}
			return res.payload;
		} else {
			console.log("error updating incentive");
		}
	},
	fetchAccountTypes: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: "/distinctAccountTypes",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ accountTypes: res.payload });
		} else {
			console.log("error fetching incentives");
			set({ accountTypes: [] });
		}
	},
	fetchAllUsers: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: "/users",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ users: res.payload });
		} else {
			console.log("error fetching users");
			set({ users: [] });
		}
	},
	fetchProducts: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: "/wholesaler",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ products: res.payload });
		} else {
			console.log("error fetching users");
			set({ products: null });
		}
	},
	fetchCustomers: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: "/buyersByAccountType",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ customers: res.payload });
		} else {
			console.log("error fetching users");
			set({ customers: null });
		}
	},
	fetchIncentivesReportTable: async (
		query: IQueryFavoriteWithSearch,
		callback?: () => void,
		callbackGrouped?: () => void
	) => {
		set({ loading: true });
		const res = await api.fetch({
			path: "/api/v2/incentives",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ incentives: res.payload, totalPages: res?.meta?.totalPages });
			if (callback) callback();
			if (callbackGrouped) callbackGrouped();
			set({ loading: false });
		} else {
			console.log("error fetching incentives");
			set({ incentives: [] });
			set({ loading: false });
		}
	},
	runQueryForIncentive: async (
		query: IQueryFavorite,
		incentiveId: string
	) => {
		const res = await api.fetch({
			path: `/runQueryForIncentive/${incentiveId}`,
			method: "GET",
			query,
		});

		if (res.ok) {
			snack.success("Action completed 👍", 10000);
			console.log("Action completed 👍", res.payload);
		} else {
			console.log("error");
		}
	},
	resetValuesForIncentive: async (
		query: IQueryFavorite,
		incentiveId: string
	) => {
		const res = await api.fetch({
			path: `/resetValuesForIncentive/${incentiveId}`,
			method: "GET",
			query,
		});

		if (res.ok) {
			snack.success("Action completed 👍", 10000);
			console.log("Action completed 👍", res.payload);
		} else {
			console.log("error");
		}
	},
	updateUserTargetAndPayout: async (
		query: IQueryFavoriteWithSearch,
		itemId: string,
		userId: string,
		payoutType: string,
		payout: number,
		target: number,
		isOpenEnded: boolean,
		threshold: { units: string; value: number },
		cap: { units: string; value: number },
		minQuantityValue: number,
		isAccountSpecific: boolean,
		isFullAmount: boolean,
		advancedBudget?: number,
		advancedPayments?: AdvancedPayment[],
		filterType?: string
	) => {
		const res = await api.fetch({
			path: "/api/v2/updateUserTargetAndPayout",
			method: "POST",
			query,
			body: {
				itemId,
				userId,
				payoutType,
				payout,
				target,
				isOpenEnded,
				threshold,
				cap,
				isIncentive: false,
				isEdited: true,
				minQuantityValue,
				isAccountSpecific,
				isFullAmount,
				advancedBudget,
				advancedPayments,
			},
		});

		if (res.ok) {
			let fetchQuery = {
				...query,
				page: get().secondaryCurrentPage,
				type: filterType,
			};

			const res = await api.fetch({
				path: "/api/v2/incentives",
				method: "GET",
				query: fetchQuery,
			});

			if (res.ok) {
				set({
					incentives: res.payload,
					totalPages: res?.meta?.totalPages,
				});

				const incentives = res.payload;
				for (const incentive of incentives) {
					if (incentive.type === "multi") {
						await get().fetchSubIncentives(query, incentive._id);
					}
					if (incentive.type === "grouped") {
						await get().fetchChildren(
							{ wholesalerId: query.wholesalerId },
							incentive._id
						);
					}
				}
				const updatedIncentives = get().incentives;
				let foundIncentive = updatedIncentives.find(
					(o) => o._id === itemId
				);
				let parentIncentive = null;

				if (!foundIncentive) {
					// If not found in main incentives, search in subIncentives
					for (const incentive of updatedIncentives) {
						if (
							incentive.type === "multi" &&
							incentive.subIncentives
						) {
							foundIncentive = incentive.subIncentives.find(
								(sub) => sub._id === itemId
							);
							if (foundIncentive) {
								parentIncentive = incentive;
								break;
							}
						}

						if (
							incentive.type === "grouped" &&
							incentive.groupedIncentives
						) {
							foundIncentive = incentive.groupedIncentives.find(
								(grouped) => grouped._id === itemId
							);
							if (foundIncentive) {
								parentIncentive = incentive;
								break;
							}
						}
					}
				}
				let filteredSubIncentives;
				if (parentIncentive && parentIncentive.type === "multi") {
					// If foundIncentive is a subIncentive, filter out the subIncentive from its parent
					filteredSubIncentives =
						parentIncentive?.subIncentives?.filter(
							(sub) => sub._id !== itemId
						);
				} else {
					// Otherwise, filter out the main incentive
					filteredSubIncentives = updatedIncentives.filter(
						(o) => o._id !== itemId
					);
				}

				let filteredGroupedIncentives;
				if (parentIncentive && parentIncentive.type === "grouped") {
					filteredGroupedIncentives =
						parentIncentive?.groupedIncentives?.filter(
							(gr) => gr._id !== itemId
						);
				} else {
					filteredGroupedIncentives = updatedIncentives.filter(
						(o) => o._id !== itemId
					);
				}

				const users = foundIncentive?.users?.map((u) => {
					if (u.user === userId) {
						return {
							...u,
							target: target,
							payout: payout,
							payoutType: payoutType,
							isOpenEnded: isOpenEnded,
							threshold: threshold,
							cap: cap,
							minQuantityValue: minQuantityValue,
							isAccountSpecific: isAccountSpecific,
							isFullAmount: isFullAmount,
							advancedBudget: advancedBudget,
							advancedPayments: advancedPayments,
						};
					}
					return u;
				});

				if (users === undefined || foundIncentive === undefined) {
					set(() => ({
						incentives: updatedIncentives,
					}));

					return updatedIncentives;
				}

				const updatedIncentive = {
					...foundIncentive,
					users: users,
				};
				set(() => ({
					incentives: parentIncentive
						? updatedIncentives.map((inc) => {
								if (inc._id === parentIncentive._id) {
									if (parentIncentive.type === "multi") {
										return {
											...parentIncentive,
											subIncentives: [
												...filteredSubIncentives,
												updatedIncentive,
											],
										};
									} else if (
										parentIncentive.type === "grouped"
									) {
										return {
											...parentIncentive,
											groupedIncentives: [
												...filteredGroupedIncentives,
												updatedIncentive,
											],
										};
									}
								}
								return inc;
						  })
						: parentIncentive?.type === "grouped"
						? [...filteredGroupedIncentives, updatedIncentive]
						: [...filteredSubIncentives, updatedIncentive],
				}));

				const rows = get().rows;
				let updatedRows = rows;

				const updatedUser = updatedIncentive.users.find(
					(u) => u.user === userId
				);
				if (updatedUser) {
					updatedRows = rows.map((row) => {
						if (row.userId === userId && row.itemId === itemId) {
							return {
								...row,
								payout: isOpenEnded
									? "∞"
									: updatedUser.totalPayout,
								target: isOpenEnded ? "∞" : target,
								payoutMTD: updatedUser.totalAchieved,
								minQuantityValue: minQuantityValue,
								eligibleQuantity: updatedUser.isAccountSpecific
									? updatedUser.eligibleQuantity
									: "-",
							};
						}
						return row;
					});

					set({ rows: updatedRows });
				}

				return parentIncentive
					? updatedIncentives.map((inc) =>
							inc._id === parentIncentive._id
								? {
										...parentIncentive,
										...(parentIncentive.type === "multi"
											? {
													subObjectives: [
														...filteredSubIncentives,
														updatedIncentive,
													],
											  }
											: parentIncentive.type === "grouped"
											? {
													groupedObjectives: [
														...filteredGroupedIncentives,
														updatedIncentive,
													],
											  }
											: {}),
								  }
								: inc
					  )
					: parentIncentive?.type === "grouped"
					? [...filteredGroupedIncentives, updatedIncentive]
					: [...filteredSubIncentives, updatedIncentive];
			} else {
				console.log("error adding incentive");
				return [];
			}
		} else {
			console.log("error adding incentives");
			return [];
		}
	},
	rejectImage: async (
		query: IGeneralQuery,
		itemId: string,
		imageId: string,
		rejectedReason: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/item/${itemId}/rejectImage/${imageId}`,
			method: "PATCH",
			query,
			body: { rejectedReason },
		});

		if (res.ok) {
			snack.success("Image was rejected 👍");

			set(() => {
				const rows = get().rows;
				const updatedRows = rows.map((row) => {
					const updatedImages = row.image.filter(
						(image: ObjIncentiveImage) => image._id !== imageId
					);
					const rejectedImage = row.image.find(
						(image: ObjIncentiveImage) => image._id === imageId
					);

					const updatedRejectedImages = rejectedImage
						? [
								...(row.rejectedImages || []),
								{
									...rejectedImage,
									rejected: true,
									rejectedReason: rejectedReason,
								},
						  ]
						: row.rejectedImages || [];

					if (rejectedImage) {
						return {
							...row,
							image: updatedImages,
							rejectedImages: updatedRejectedImages,
							totalItems: res.payload.totalItems,
							status: res.payload.percentageAchieved,
							payoutMTD: res.payload.totalAchieved,
						};
					}

					return {
						...row,
						image: updatedImages,
						rejectedImages: updatedRejectedImages,
					};
				});

				return { rows: updatedRows };
			});
		} else {
			snack.error("Failed to reject image 👎");
		}
	},
	unRejectImage: async (
		query: IGeneralQuery,
		itemId: string,
		imageId: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/item/${itemId}/unRejectImage/${imageId}`,
			method: "PATCH",
			query,
		});

		if (res.ok) {
			snack.success("Image was restored 👍");

			set(() => {
				const rows = get().rows;
				const updatedRows = rows.map((row) => {
					const unrejectedImage = row.rejectedImages?.find(
						(image: ObjIncentiveImage) => image._id === imageId
					);

					const updatedRejectedImages =
						row.rejectedImages?.filter(
							(image: ObjIncentiveImage) => image._id !== imageId
						) || [];

					const updatedImages = unrejectedImage
						? [
								...(row.image || []),
								{
									...unrejectedImage,
									rejected: false,
									rejectedReason: "",
								},
						  ]
						: row.image || [];

					if (unrejectedImage) {
						return {
							...row,
							image: updatedImages,
							rejectedImages: updatedRejectedImages,
							totalItems: res.payload.totalItems,
							status: res.payload.percentageAchieved,
							payoutMTD: res.payload.totalAchieved,
						};
					}

					return {
						...row,
						image: updatedImages,
						rejectedImages: updatedRejectedImages,
					};
				});

				return { rows: updatedRows };
			});
		} else {
			snack.error("Failed to restore image 👎");
		}
	},
}));
