import React, { useState } from "react";
import { Objective } from "../types/objectiveTypes";
import { useStyles } from "./FavoritesComponent.styles";
import { Item } from "../types/commonObjIncTypes";
import ItemComponent from "../common/ItemComponent";
import useTags from "../hooks/useTags";
import moment from "moment";
import useItems from "../hooks/useItems";
import useHandleItemsUpdate from "../hooks/useHandleItemsUpdate";

export const FavoriteObjectivesComponent: React.FC = () => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedTag, setSelectedTag] = useState<string>("");
	const [periodStart, setPeriodStart] = useState<string | null>(null);
	const [periodEnd, setPeriodEnd] = useState<string | null>(null);
	const [selectedType, setSelectedType] = useState<string | undefined>("")

	const { items, currentPage, setCurrentPage, totalPages, loading } =
		useItems({
			searchPhrase: searchTerm !== "" ? searchTerm : undefined,
			type: selectedType,
			tag: selectedTag || undefined,
			periodStart,
			periodEnd,
			itemsType: "objectives",
			isObjective: true,
			isArchived: false,
			isFavorite: true,
		});

	const { tags } = useTags();

	const tagOptions = tags.map((tag) => ({
		value: tag._id,
		label: tag.name,
	}));

	const { addItem, editItem, deleteItem } =
		useHandleItemsUpdate({ itemType: "objective", isObjective: true });

	const handleAdd = async (newData: Omit<Item, "_id">) => {
		return await addItem(newData as Omit<Objective, "_id">);
	};

	const handleEdit = (id: string, updated: Partial<Item>) => {
		editItem(id, updated as Partial<Objective>);
	};

	const handleDelete = (id: string) => {
		deleteItem(id);
	};

	const handleFavorite = (id: string) => {
		const item = items.find((it) => it._id === id);
		if (item) {
			editItem(id, {
				...item,
				favorite: !item.favorite,
			});
		}
	};

	const handleDuplicate = (id: string) => {
		const item = items.find((it) => it._id === id);

		if (item) {
			const newName = `${item.name} copy`;
			const { _id: id, customId, users: originalUsers, ...restOfObject } = item;
			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
				totalAchieved: 0,
				percentageAchieved: 0,
				totalItems: 0,
				totalPayout: 0,
				checks: [],
				isEdited: false,
			}));

			handleAdd({
				...restOfObject,
				name: newName,
				favorite: false,
				users: initializedUsers,
			});
		}
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleTypeChange = (selectedOption: any) => {
		if (selectedOption.value === selectedType) {
			setSelectedType("");
		} else {
			setSelectedType(selectedOption.value);
		}
	};

	const handleTagChange = (selectedOption: any) => {
		if (selectedOption.value === selectedTag) {
			setSelectedTag("");
		} else {
			setSelectedTag(selectedOption.value);
		}
	};

	const handlePeriodStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
			setPeriodStart(formattedDate);
		} else {
			setPeriodStart(null);
		}
	};

	const handlePeriodEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
			setPeriodEnd(formattedDate);
		} else {
			setPeriodEnd(null);
		}
	};
	
	return (
		<div className={classes.container}>
			<div className={classes.listWrapper}>
				<ItemComponent
					items={items}
					itemType="objective"
					showAddButton={false}
					onAdd={handleAdd}
					onEdit={handleEdit}
					onDelete={handleDelete}
					onFavorite={handleFavorite}
					onDuplicate={handleDuplicate}
					currentPage={currentPage}
					loading={loading}
					setCurrentPage={setCurrentPage}
					totalPages={totalPages}
					onArchive={function (id: string): void {
						throw new Error("Function not implemented.");
					}}
					onAction={function (id: string): void {
						throw new Error("Function not implemented.");
					}}
					onReset={() => {}}
					searchTerm={searchTerm}
					onSearchChange={handleSearchChange}
					tags={tagOptions}
					selectedTag={selectedTag}
					onTagChange={handleTagChange}
					periodStart={periodStart}
					periodEnd={periodEnd}
					onPeriodStartChange={handlePeriodStartChange}
					onPeriodEndChange={handlePeriodEndChange}
					selectedType={selectedType}
					onTypeChange={handleTypeChange}
				/>
			</div>
		</div>
	);
};
