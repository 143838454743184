import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { useEffect, useState } from "react";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useGroupedItems = ({ itemId, isObjective }: { itemId: string, isObjective: boolean }) => {
	const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;

	const [pageSize, setPageSize] = useState(10);

	const loadingGrouped = commonObjIncStoreZustand(
		(state) => state.loadingGroupedChildren
	);

	const currentGroupedPage = commonObjIncStoreZustand(
		(state) => state.currentGroupedChildrenPage
	);
	const setCurrentGroupedPage = commonObjIncStoreZustand(
		(state) => state.setCurrentGroupedChildrenPage
	);

	const totalGroupedPages = commonObjIncStoreZustand(
		(state) => state.totalGroupedChildrenPages
	);

	const addGroupedItems = (
		children: { id: string; weight: number }[],
		parentItemId: string
	) => {
		const obj = commonObjIncStoreZustand
			.getState()
			.addGroupedItems(
				{ wholesalerId },
				children,
				parentItemId,
				isObjective
			);
		commonObjIncStoreZustand
			.getState()
			.fetchGroupedItems({ wholesalerId }, parentItemId, isObjective);

		return obj;
	};


	useEffect(() => {
		commonObjIncStoreZustand
			.getState()
			.fetchGroupedItems(
				{ wholesalerId, page: currentGroupedPage, limit: pageSize },
				itemId,
				isObjective
			);
	}, [itemId, wholesalerId, currentGroupedPage, pageSize, isObjective]);

	const groupedChildren = commonObjIncStoreZustand(
		(state) => state.groupedChildren
	);
	return {
		addGroupedItems,
		currentGroupedPage,
		totalGroupedPages,
		setCurrentGroupedPage,
		groupedChildren,
		loadingGrouped,
		setPageSize,
	};
};

export default useGroupedItems;
