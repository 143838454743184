import React, { useState } from "react";
import {
	Button,
	TextField,
	Dialog,
	DialogContent,
	DialogActions,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	DialogTitle,
} from "@material-ui/core";
import { MeasureState, Objective, subObjectiveTypes } from "../../../types/objectiveTypes";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useStyles } from "./AddNewSubItemComponent.styles";
import { Item, SemiItem } from "../../../types/commonObjIncTypes";
import { Incentive } from "../../../types/incentivesTypes";

interface AddNewSubItemComponentProps {
	itemType: "objective" | "incentive";
	initState: SemiItem;
	parentItemId: string;
	open: boolean;
	onSave: (item: Omit<Objective | Incentive, "_id">, parentItemId: string) => void;
	onClose: () => void;
}

export const AddNewSubItemComponent: React.FC<AddNewSubItemComponentProps> = ({
	itemType,
	initState,
	parentItemId,
	open,
	onSave,
	onClose,
}) => {
	const [item, setSubItem] = useState<SemiItem>(initState);
	const classes = useStyles();
	const handleChange = (
		event: React.ChangeEvent<{ name?: string; value: unknown }>
	) => {
		const name = event.target.name as keyof Item;
		setSubItem((prev) => ({
			...prev,
			[name]: event.target.value,
		}));
	};
	const handleSubmit = () => {
		if (item.type === "quantity") {
			onSave(
				{
					...item,
					measure: {
						label: "Vol (CEs)",
						startDate: new Date().toISOString(),
						endDate: new Date().toISOString(),
						checked: true,
						trackingOption: "default",
						trackingPeriodStart: new Date().toISOString(),
						trackingPeriodEnd: new Date().toISOString(),
						referenceTrackingPeriod: new Date().toISOString(),
					} as MeasureState,
				},
				parentItemId
			);
			if (itemType === "objective") {
				setSubItem({
					...initState,
					measure: {
						label: "Vol (CEs)",
						startDate: new Date().toISOString(),
						endDate: new Date().toISOString(),
						checked: false,
						symbol: "$",
						trackingOption: "default",
						trackingPeriodStart: new Date().toISOString(),
						trackingPeriodEnd: new Date().toISOString(),
						referenceTrackingPeriod: new Date().toISOString(),
					} as MeasureState,
				});
			} else {
				setSubItem({
					...initState,
					measure: {
						label: "Vol (CEs)",
						startDate: new Date().toISOString(),
						endDate: new Date().toISOString(),
						checked: false,
						symbol: "$",
						trackingOption: "default",
						trackingPeriodStart: new Date().toISOString(),
						trackingPeriodEnd: new Date().toISOString(),
						referenceTrackingPeriod: new Date().toISOString(),
					} as MeasureState,
				});
			}
		} else {
			onSave(item, parentItemId);
			setSubItem(initState);
		}

		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
			className={classes.dialog}
		>
			<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
				{`New Sub ${itemType.toLocaleUpperCase()}`}
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Name"
					type="text"
					fullWidth
					name="name"
					placeholder="Max of characters"
					value={item.name}
					onChange={handleChange}
				/>
				<FormControl fullWidth className={classes.formControl}>
					<InputLabel id="type-label">Type</InputLabel>
					<Select
						labelId="type-label"
						id="type"
						name="type"
						value={item.type}
						onChange={handleChange}
						label="Type"
					>
						{subObjectiveTypes.map((typeObj) => (
							<MenuItem key={typeObj.value} value={typeObj.value}>
								{typeObj.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions className={`${classes.buttonContainer}`}>
				<Button
					onClick={handleSubmit}
					className={`${classes.button} ${classes.addButton}`}
				>
					Add
				</Button>
				<Button
					onClick={onClose}
					color="default"
					className={`${classes.button} ${classes.cancelButton}`}
				>
					Cancel
				</Button>
				<Button
					onClick={onClose}
					color="default"
					className={`${classes.button} ${classes.cancelButton} ${classes.addFromFavoritesButton}`}
				>
					Add from favorites
				</Button>
			</DialogActions>
		</Dialog>
	);
};
