import React, { useEffect, useState } from "react";
import { Item } from "../../types/commonObjIncTypes";
import ItemComponent from "../../common/ItemComponent";
import { Incentive } from "../../types/incentivesTypes";
import useTags from "../../hooks/useTags";
import moment from "moment";
import useHandleItemsUpdate from "../../hooks/useHandleItemsUpdate";
import useItems from "../../hooks/useItems";

export const IncentivesComponent: React.FC = () => {
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedTag, setSelectedTag] = useState<string>("");
	const [periodStart, setPeriodStart] = useState<string | null>(null);
	const [periodEnd, setPeriodEnd] = useState<string | null>(null);
	const [selectedType, setSelectedType] = useState<string | undefined>("")


	const { items, setCurrentPage, currentPage, totalPages, loading } =
		useItems({
			searchPhrase: searchTerm !== "" ? searchTerm : undefined,
			type: selectedType,
			tag: selectedTag || undefined,
			periodStart,
			periodEnd,
			itemsType: "incentives",
			isObjective: false,
			isArchived: false,
			isFavorite: false,
		});

	const { tags } = useTags();

	const tagOptions = tags.map((tag) => ({
		value: tag._id,
		label: tag.name,
	}));


	const { addItem, editItem, deleteItem, runQuery, resetValues } =
		useHandleItemsUpdate({ itemType: "incentive", isObjective: false });

	const handleAddIncentive = async (
		newIncentiveData: Omit<Item, "_id">,
		parentId?: string
	) => {
		return await addItem(
			newIncentiveData as Omit<Incentive, "_id">,
			parentId
		);
	};

	const handleEditIncentive = (id: string, updatedIncentive: Partial<Item>) => {
		editItem(id, updatedIncentive as Partial<Incentive>);
	};

	const handleDeleteIncentive = (id: string) => {
		deleteItem(id);
	};

	const handleFavoriteIncentive = (id: string) => {
		const incentive = items.find((incentive) => incentive._id === id);
		if (incentive) {
			editItem(id, {
				...incentive,
				favorite: !incentive.favorite,
			});
		}
	};

	const handleDuplicateIncentive = (id: string) => {
		const incentive = items.find((incentive) => incentive._id === id);

		if (incentive) {
			const newName = `${incentive.name} copy`;
			const {
				_id: id,
				customId,
				users: originalUsers,
				...restOfObject
			} = incentive;

			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
				totalAchieved: 0,
				percentageAchieved: 0,
				totalItems: 0,
				totalPayout: 0,
				checks: [],
				isEdited: false,
			}));

			handleAddIncentive(
				{
					...restOfObject,
					name: newName,
					favorite: false,
					users: initializedUsers,
				},
				id
			);
		}
	};

	const handleArchiveIncentive = (id: string) => {
		const incentive = items.find((incentive) => incentive._id === id);
		if (incentive) {
			editItem(id, {
				...incentive,
				archived: !incentive.archived,
			});
		}
	};
	const handleAction = (id: string) => {
		const incentive = items.find((incentive) => incentive._id === id);
		if (incentive) {
			runQuery(incentive._id);
		}
	};

	const handleReset = (id: string) => {
		const incentive = items.find((incentive) => incentive._id === id);
		if (incentive) {
			resetValues(incentive._id);
		}
	};
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleTypeChange = (selectedOption: any) => {
		if (selectedOption.value === selectedType) {
			setSelectedType("");
		} else {
			setSelectedType(selectedOption.value);
		}
	};

	const handleTagChange = (selectedOption: any) => {
		if (selectedOption.value === selectedTag) {
			setSelectedTag("");
		} else {
			setSelectedTag(selectedOption.value);
		}
	};

	const handlePeriodStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
			setPeriodStart(formattedDate);
		} else {
			setPeriodStart(null);
		}
	};

	const handlePeriodEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
			setPeriodEnd(formattedDate);
		} else {
			setPeriodEnd(null);
		}
	};

	return (
		<ItemComponent
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			totalPages={totalPages}
			items={items}
			loading={loading}
			itemType="incentive"
			onAdd={handleAddIncentive}
			onEdit={handleEditIncentive}
			onDelete={handleDeleteIncentive}
			onFavorite={handleFavoriteIncentive}
			onDuplicate={handleDuplicateIncentive}
			onArchive={handleArchiveIncentive}
			onAction={handleAction}
			onReset={handleReset}
			searchTerm={searchTerm}
			onSearchChange={handleSearchChange}
			tags={tagOptions}
			selectedTag={selectedTag}
			onTagChange={handleTagChange}
			periodStart={periodStart}
			periodEnd={periodEnd}
			onPeriodStartChange={handlePeriodStartChange}
			onPeriodEndChange={handlePeriodEndChange}
			selectedType={selectedType}
			onTypeChange={handleTypeChange}
		/>
	);
};
