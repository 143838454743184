import React, { useEffect, useState } from "react";
import useUsers from "../../../../hooks/useUsers";
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
} from "@material-ui/core";
import Select from "react-select";
import { IUser } from "../../../../../../interfaces";
import { useStyles } from "./UsersSelection.styles";
import useTeamMembers from "../../../../hooks/useTeamMembers";
import useToRemoveTeamMembers from "../../../../hooks/useToRemoveTeamMembers";
import { UserRole } from "../../utils";
import useSuperTeamMembers from "../../../../hooks/useSuperTeamMembers";
import useToRemoveSuperTeamMembers from "../../../../hooks/useToRemoveSuperTeamMembers";

type CustomUsersSelectionProps = {
	initialSelectedUserIds: UserRole[];
	onChange: (selectedUsers: UserRole[]) => void;
	hideFromSalesReps?: boolean;
	onCheckChange: (checked: boolean) => void;
};

export const CustomUsersSelection: React.FC<CustomUsersSelectionProps> = ({
	initialSelectedUserIds,
	onChange,
	hideFromSalesReps,
	onCheckChange,
}) => {
	const classes = useStyles();
	const { groupedUsers, users } = useUsers();
	const [selectedLeadership, setSelectedLeadership] = useState<IUser[]>([]);
	const [selectedTeamLead, setSelectedTeamLead] = useState<IUser[]>([]);
	const [selectedSalesRep, setSelectedSalesRep] = useState<IUser[]>([]);
	const [allSelected, setAllSelected] = useState<boolean>(false);
	const [lastSelectedLeadership, setLastSelectedLeadership] =
		useState<IUser | null>(null);
	const [lastSelectedTeamLead, setLastSelectedTeamLead] =
		useState<IUser | null>(null);
	const [removedLeaderships, setRemovedLeaderships] = useState<IUser[]>([]);
	const [removedTeamLeads, setRemovedTeamLeads] = useState<IUser[]>([]);
	const [includeSalesReps, setIncludeSalesReps] = useState<boolean>(false);

	const { teamMembers } = useTeamMembers({
		userId: lastSelectedTeamLead?.customId,
	});
	const { teamMembersToRemove, removeMembers } = useToRemoveTeamMembers({
		userIds: removedTeamLeads.map((user) => user.customId),
	});

	const { superTeamMembers } = useSuperTeamMembers({
		userId: lastSelectedLeadership?.customId,
	});

	const { superTeamMembersToRemove, removeSuperMembers } = useToRemoveSuperTeamMembers({
		userIds: removedLeaderships.map((user) => user.customId),
	});

	const mapUserIdsToUsers = (userIds: string[], allUsers: IUser[]): IUser[] => {
		return userIds
			.map((userId) => allUsers.find((user) => user.customId === userId))
			.filter((user) => user) as IUser[];
	};
	
	useEffect(() => {
		const initialUserIds = initialSelectedUserIds.map((user) => user.user);
		const initialUsers = mapUserIdsToUsers(initialUserIds, users);
		setSelectedLeadership(initialUsers.filter((user) => user.leadership));
		setSelectedTeamLead(
			initialUsers.filter(
				(user) => user.role === "teamLead" && !user.leadership
			)
		);
		setSelectedSalesRep(
			initialUsers.filter((user) => user.role === "salesRep")
		);
	}, [initialSelectedUserIds, users]);

	useEffect(() => {
		const allUserIds = [
			...(groupedUsers?.Leadership || []),
			...(groupedUsers?.TeamLead || []),
			...(groupedUsers?.SalesRep || []),
		].map((user) => user.customId);
		const selectedUserIds = [
			...selectedLeadership,
			...selectedTeamLead,
			...selectedSalesRep,
		].map((user) => user.customId);

		setAllSelected(allUserIds.length === selectedUserIds.length);
	}, [selectedLeadership, selectedTeamLead, selectedSalesRep, groupedUsers]);

	useEffect(() => {
		if (lastSelectedTeamLead && teamMembers.length > 0) {
			const newSalesReps = teamMembers.filter(
				(member: any) =>
					!selectedSalesRep.some(
						(existingRep) => existingRep.customId === member.customId
					)
			);
			if (newSalesReps.length > 0) {
				const updatedSalesReps = [...selectedSalesRep, ...newSalesReps];
				setSelectedSalesRep(updatedSalesReps);

				updateAllSelectedState([
					...selectedLeadership.map((user) => ({
						user: user.customId,
						isTeamLead: true,
						isLeadership: true,
					})),
					...selectedTeamLead.map((user) => ({
						user: user.customId,
						isTeamLead: true,
						isLeadership: false,
					})),
					...updatedSalesReps.map((user) => ({
						user: user.customId,
						isTeamLead: false,
						isLeadership: false,
					})),
				]);
			}
		}
	}, [teamMembers, lastSelectedTeamLead]);
	
	useEffect(() => {
		if (lastSelectedLeadership && superTeamMembers.length > 0) {
			const newSalesReps = superTeamMembers.filter(
				(member: any) =>
					!selectedSalesRep.some(
						(existingRep) => existingRep.customId === member.customId
					) && !member.isTeamLead 
			);

			const newTeamLeads = superTeamMembers.filter(
				(member: any) =>
					!selectedTeamLead.some(
						(existingLead) => existingLead.customId === member.customId
					) && member.isTeamLead 
			);
			const updatedSalesReps = newSalesReps.length > 0
				? [...selectedSalesRep, ...newSalesReps]
				: selectedSalesRep;

			const updatedTeamLeads = newTeamLeads.length > 0
				? [...selectedTeamLead, ...newTeamLeads]
				: selectedTeamLead;
			
				setSelectedSalesRep(updatedSalesReps);
				setSelectedTeamLead(updatedTeamLeads)

				updateAllSelectedState([
					...selectedLeadership.map((user) => ({
						user: user.customId,
						isTeamLead: true,
						isLeadership: true,
					})),
					...updatedTeamLeads.map((user) => ({
						user: user.customId,
						isTeamLead: true,
						isLeadership: false,
					})),
					...updatedSalesReps.map((user) => ({
						user: user.customId,
						isTeamLead: false,
						isLeadership: false,
					})),
				]);
			
		}
	}, [superTeamMembers, lastSelectedLeadership]);

	useEffect(() => {
		if (teamMembersToRemove.length > 0) {
			const salesRepsToRemove = selectedSalesRep.filter((salesRep: IUser) =>
				teamMembersToRemove.some(
					(member: IUser) => member.customId === salesRep.customId
				)
			);

			if (salesRepsToRemove.length > 0) {
				setSelectedSalesRep((prevSalesReps) =>
					prevSalesReps.filter(
						(salesRep: IUser) =>
							!salesRepsToRemove.some(
								(toRemove: IUser) => toRemove.customId === salesRep.customId
							)
					)
				);

				updateAllSelectedState([
					...selectedLeadership.map((user: IUser) => ({
						user: user.customId,
						isTeamLead: true,
						isLeadership: true,
					})),
					...selectedTeamLead.map((user: IUser) => ({
						user: user.customId,
						isTeamLead: true,
						isLeadership: false,
					})),
					...selectedSalesRep
						.filter(
							(salesRep: IUser) =>
								!salesRepsToRemove.some(
									(toRemove: IUser) => toRemove.customId === salesRep.customId
								)
						)
						.map((user: IUser) => ({
							user: user.customId,
							isTeamLead: false,
							isLeadership: false,
						})),
				]);

				removeMembers(
					teamMembersToRemove.map((member: IUser) => member.customId)
				).then(() => {
					setRemovedTeamLeads([]);
				});
			}
		}
	}, [
		teamMembersToRemove,
		removedTeamLeads
	]);

	useEffect(() => {
		if (superTeamMembersToRemove.length > 0) {
			const salesRepsToRemove = selectedSalesRep.filter((salesRep: IUser) =>
				superTeamMembersToRemove.some(
					(member: IUser) => member.customId === salesRep.customId
				)
			);

			const teamLeadsToRemove = selectedTeamLead.filter((teamLead: IUser) =>
				superTeamMembersToRemove.some(
					(member: IUser) => member.customId === teamLead.customId
				)
			);

			if (salesRepsToRemove.length > 0 || teamLeadsToRemove.length > 0) {
				if (salesRepsToRemove.length > 0) {
					setSelectedSalesRep((prevSalesReps) =>
						prevSalesReps.filter(
							(salesRep: IUser) =>
								!salesRepsToRemove.some(
									(toRemove: IUser) => toRemove.customId === salesRep.customId
								)
						)
					);
				}

				if (teamLeadsToRemove.length > 0) {
					setSelectedTeamLead((prevTeamLeads) =>
						prevTeamLeads.filter(
							(teamLead: IUser) =>
								!teamLeadsToRemove.some(
									(toRemove: IUser) => toRemove.customId === teamLead.customId
								)
						)
					);
				}

				if (salesRepsToRemove.length > 0 || teamLeadsToRemove.length > 0) {
					updateAllSelectedState([
						...selectedLeadership.map((user: IUser) => ({
							user: user.customId,
							isTeamLead: true,
							isLeadership: true,
						})),
						...selectedTeamLead
							.filter(
								(teamLead: IUser) =>
									!teamLeadsToRemove.some(
										(toRemove: IUser) => toRemove.customId === teamLead.customId
									)
							)
							.map((user: IUser) => ({
								user: user.customId,
								isTeamLead: true,
								isLeadership: false,
							})),
						...selectedSalesRep
							.filter(
								(salesRep: IUser) =>
									!salesRepsToRemove.some(
										(toRemove: IUser) => toRemove.customId === salesRep.customId
									)
							)
							.map((user: IUser) => ({
								user: user.customId,
								isTeamLead: false,
								isLeadership: false,
							})),
					]);

					removeSuperMembers(
						superTeamMembersToRemove.map((member: IUser) => member.customId)
					).then(() => {
						setRemovedLeaderships([]);
					});
				}
			}
		}
	}, [superTeamMembersToRemove, removedLeaderships]);

	const handleLeadershipSelectChange = (selectedOptions: any) => {
		const selectedUserIds = selectedOptions.map((option: any) => option.value);
		const selectedUsers = users.filter((user: IUser) =>
			selectedUserIds.includes(user.customId)
		);
		setSelectedLeadership(selectedUsers);

		const newlySelected = selectedUsers.filter(
			(user) =>
				!selectedLeadership.some(
					(existingUser) => existingUser.customId === user.customId
				)
		);

		if (includeSalesReps && newlySelected.length > 0) {
			setLastSelectedLeadership(newlySelected[newlySelected.length - 1]);
		} else if (selectedUsers.length === 0) {
			setLastSelectedLeadership(null);
		}

		const previouslySelectedIds = selectedLeadership.map((user) => user.customId);
		const newlyRemovedLeadership = previouslySelectedIds.filter(
			(id) => !selectedUserIds.includes(id)
		);

		if (newlyRemovedLeadership.length > 0) {
			const removedUsers = users.filter((user) =>
				newlyRemovedLeadership.includes(user.customId)
			);
			setRemovedLeaderships(removedUsers);
			setLastSelectedLeadership(null);
			setLastSelectedTeamLead(null);
		} else {
			setRemovedLeaderships([]);
		}
		if (includeSalesReps) {
			removeSuperMembers(newlyRemovedLeadership);
		}

		updateAllSelectedState([
			...selectedUserIds.map((user: string) => ({
				user: user,
				isTeamLead: true,
				isLeadership: true,
			})),
			...selectedTeamLead.map((user) => ({
				user: user.customId,
				isTeamLead: true,
				isLeadership: false,
			})),
			...selectedSalesRep.map((user) => ({
				user: user.customId,
				isTeamLead: false,
				isLeadership: false,
			})),
		]);
	};

	const handleTeamLeadSelectChange = (selectedOptions: any) => {
		const selectedUserIds = selectedOptions.map((option: any) => option.value);
		const selectedUsers = users.filter((user: IUser) =>
			selectedUserIds.includes(user.customId)
		);
		setSelectedTeamLead(selectedUsers);

		const newlySelected = selectedUsers.filter(
			(user) =>
				!selectedTeamLead.some(
					(existingUser) => existingUser.customId === user.customId
				)
		);

		if (includeSalesReps && newlySelected.length > 0) {
			setLastSelectedTeamLead(newlySelected[newlySelected.length - 1]);
		} else if (selectedUsers.length === 0) {
			setLastSelectedTeamLead(null);
		}

		const previouslySelectedIds = selectedTeamLead.map((user) => user.customId);
		const newlyRemovedTeamLeads = previouslySelectedIds.filter(
			(id) => !selectedUserIds.includes(id)
		);

		if (newlyRemovedTeamLeads.length > 0) {
			const removedUsers = users.filter((user) =>
				newlyRemovedTeamLeads.includes(user.customId)
			);
			setRemovedTeamLeads(removedUsers);
			setLastSelectedTeamLead(null);
		} else {
			setRemovedTeamLeads([]);
		}
		if (includeSalesReps) {
			removeMembers(newlyRemovedTeamLeads);
		}
		updateAllSelectedState([
			...selectedLeadership.map((user) => ({
				user: user.customId,
				isTeamLead: true,
				isLeadership: true,
			})),
			...selectedUserIds.map((user: string) => ({
				user: user,
				isTeamLead: true,
				isLeadership: false,
			})),
			...selectedSalesRep.map((user) => ({
				user: user.customId,
				isTeamLead: false,
				isLeadership: false,
			})),
		]);
	};

	const handleSalesRepSelectChange = (selectedOptions: any) => {
		const selectedUserIds = selectedOptions.map((option: any) => option.value);
		const selectedUsers = users.filter((user: IUser) =>
			selectedUserIds.includes(user.customId)
		);
		setSelectedSalesRep(selectedUsers);
		updateAllSelectedState([
			...selectedLeadership.map((user) => ({
				user: user.customId,
				isTeamLead: true,
				isLeadership: true,
			})),
			...selectedTeamLead.map((user) => ({
				user: user.customId,
				isTeamLead: true,
				isLeadership: false,
			})),
			...selectedUserIds.map((user: string) => ({
				user: user,
				isTeamLead: false,
				isLeadership: false,
			})),
		]);
	};

	const updateAllSelectedState = (selectedUserIds: UserRole[]) => {
		const allUserIds = [
			...(groupedUsers?.Leadership || []),
			...(groupedUsers?.TeamLead || []),
			...(groupedUsers?.SalesRep || []),
		].map((user) => user.customId);
		setAllSelected(allUserIds.length === selectedUserIds.length);
		onChange(selectedUserIds);
	};

	const handleSelectAllOrRemoveAll = () => {
		if (allSelected) {
			setSelectedLeadership([]);
			setSelectedTeamLead([]);
			setSelectedSalesRep([]);
			onChange([]);
		} else {
			const allLeadership = groupedUsers?.Leadership || [];
			const allTeamLead = groupedUsers?.TeamLead || [];
			const allSalesRep = groupedUsers?.SalesRep || [];

			setSelectedLeadership(allLeadership);
			setSelectedTeamLead(allTeamLead);
			setSelectedSalesRep(allSalesRep);

			const allUserIds = [
				...allLeadership.map((user) => ({
					user: user.customId,
					isTeamLead: true,
					isLeadership: true,
				})),
				...allTeamLead.map((user) => ({
					user: user.customId,
					isTeamLead: true,
					isLeadership: false,
				})),
				...allSalesRep.map((user) => ({
					user: user.customId,
					isTeamLead: false,
					isLeadership: false,
				})),
			];

			onChange(allUserIds);
		}
	};

	const handleCheckboxChange =
		(setter: (checked: boolean) => void) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setter(event.target.checked);
		};

	return (
		<div className={classes.container}>
			<div className={classes.buttons}>
				<Button
					variant="outlined"
					color="primary"
					onClick={handleSelectAllOrRemoveAll}
					size="small"
					className={classes.button}
				>
					{allSelected ? "Remove All" : "Select All"}
				</Button>
				<FormControlLabel
					control={
						<Checkbox
							checked={hideFromSalesReps}
							onChange={handleCheckboxChange(onCheckChange)}
						/>
					}
					label="Hide from sales reps"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={includeSalesReps}
							onChange={handleCheckboxChange(setIncludeSalesReps)}
						/>
					}
					label="Include team members"
				/>
			</div>
			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="leadership"
					placeholder="Select Leadership"
					options={groupedUsers.Leadership.map((user) => ({
						value: user.customId,
						label: user.name,
					}))}
					value={selectedLeadership.map((user) => ({
						value: user.customId,
						label: user.name,
					}))}
					onChange={(multi) => handleLeadershipSelectChange(multi)}
				/>
			</FormControl>

			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="teamLead"
					placeholder="Select Team Lead"
					options={groupedUsers.TeamLead.map((user) => ({
						value: user.customId,
						label: user.name,
					}))}
					value={selectedTeamLead.map((user) => ({
						value: user.customId,
						label: user.name,
					}))}
					onChange={(multi) => handleTeamLeadSelectChange(multi)}
				/>
			</FormControl>

			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="salesRep"
					placeholder="Select Sales Rep"
					options={groupedUsers.SalesRep.map((user) => ({
						value: user.customId,
						label: `${user.name}${
							user.routeNumber?.length
								? ` - ${user.routeNumber.join(", ")}`
								: ""
						}`,
					}))}
					value={selectedSalesRep.map((user) => ({
						value: user.customId,
						label: `${user.name}${
							user.routeNumber?.length
								? ` - ${user.routeNumber.join(", ")}`
								: ""
						}`,
					}))}
					onChange={(multi) => handleSalesRepSelectChange(multi)}
				/>
			</FormControl>
		</div>
	);
};
